import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { configUrl } from "../../config";
import { LoginContext } from "../../contexts/LoginContext";

export default function useGetAllNotifications(userId) {
  const { isParentLogin } = React.useContext(LoginContext);
  const notificationUserType = isParentLogin == true ? 2 : 1;
  return useQuery([`testsByUser${userId}`, userId], async () => {
    console.log('in here')
    if (!userId) {
      return;
    }
    let result = await axios.get(
      `${configUrl.AssessmentServer}/api/Counselling/getAllNotifications/${userId}?notificationUserType=${notificationUserType}`
    );

    return result.data;
  },
    {
      staleTime: 1000 * 60 * 5,
    });
}
