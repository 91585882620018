import axios from "axios";
import { configUrl } from "../config";

const getProviderContentCuratorIds = async (providerId) => {
    console.log(providerId, 'paymentData')
    return axios.get(
        `${configUrl.AssessmentServer}/api/Provider/getProviderContentCuratorIds/${providerId}`)
        .then((response) => {
            console.log("contentcuratorids:", response.data);
            return response.data;
        })
        .catch((error) => {
            console.error("daat not found:", error);
            return error;
        });

};
export default getProviderContentCuratorIds;
