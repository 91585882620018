import axios from "axios";
import { useQuery } from "react-query";
import { configUrl } from "../config";

export default function useGetCourseCount() {
  return useQuery("courseCount", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Courses/getCourseCount`
    );
    let QuestionCountResponse = await axios.get(
      `${configUrl.AssessmentServer}/api/Questions/getQuestionsCount`
    );
    let StudentUsersCountResponse = await axios.get(
      `${configUrl.AssessmentServer}/api/User/getStudentUsersCount`
    );
    return {
      courseCount: response.data,
      questionCount: QuestionCountResponse.data,
      StudentUsersCount: StudentUsersCountResponse.data
    };
  });
}
