// src/theme.js
import { createTheme } from '@mui/material/styles';

const themeColors = {
    purple: {
        primary: '#5C4892',
        secondary: '#DD2466',
        background: '#EDE7F6',
        secondaryBackground: '#12B25C',
        // 
        navLinks: '#ffffff',
        navBackground: '#5C4892',
        activeNavs: '#5C4892',
        activeNavsLinks: '#DD2466',
        activeNavsShadow: '#DD2466',

        scoreCardText1: '#ffffff',
        scoreCardText2: '#ffffff',
        scoreCardText3: '#ffffff',
        scoreCardText4: '#ffffff',
        scoreCardBackground1: '#00ACC1',
        scoreCardBackground2: '#FC9309',
        scoreCardBackground3: '#dd2466',
        scoreCardBackground4: '#59B15D',

        heading: '#5C4892',
        subHeading: '#272740',
        sectionHeading: '#5C4892',

        filterBackground: '#5C4892',
        filterText: '#ffffff',
        activeFilter: '#ffffff',
        activeFilterBackground: '#DD2466',

        carauselButtons: '#5C4892',
        cardBackground: '#ffffff',
        primaryButtonBackground: '#5C4892',
        secondaryButtonBackground: '#DD2466',
        drawerBackground: '#49367e'
    },
    green: {
        primary: '#4CAF50',
        secondary: '#FF9800',
        background: '#E8F5E9',
        secondaryBackground: '#12B25C',
        // 
        navLinks: '#ffffff',
        navBackground: '#4CAF50',
        activeNavs: '#4CAF50',
        activeNavsLinks: '#FF9800',
        activeNavsShadow: '#FF9800',

        scoreCardText1: '#ffffff',
        scoreCardText2: '#ffffff',
        scoreCardText3: '#ffffff',
        scoreCardText4: '#ffffff',
        scoreCardBackground1: '#00ACC1',
        scoreCardBackground2: '#FC9309',
        scoreCardBackground3: '#dd2466',
        scoreCardBackground4: '#59B15D',

        heading: '#4CAF50',
        subHeading: '#FF9800',
        sectionHeading: '#4CAF50',

        filterBackground: '#4CAF50',
        filterText: '#ffffff',
        activeFilter: '#ffffff',
        activeFilterBackground: '#FF9800',

        carauselButtons: '#4CAF50',
        cardBackground: '#ffffff',
        primaryButtonBackground: '#4CAF50',
        secondaryButtonBackground: '#FF9800',
        drawerBackground: '#59b15d'
    },
    blue: {
        primary: '#2196F3',
        secondary: '#FFC107',
        background: '#E3F2FD', // Light blue background
        secondaryBackground: '#12B25C',
        // 
        navLinks: '#ffffff',
        navBackground: '#2196F3',
        activeNavs: '#2196F3',
        // activeNavs:'#4CAF50',
        activeNavsLinks: '#ffffff',
        activeNavsShadow: '#FFC107',

        scoreCardText1: '#ffffff',
        scoreCardText2: '#ffffff',
        scoreCardText3: '#ffffff',
        scoreCardText4: '#ffffff',
        scoreCardBackground1: '#00ACC1',
        scoreCardBackground2: '#FC9309',
        scoreCardBackground3: '#dd2466',
        scoreCardBackground4: '#59B15D',

        heading: '#2196F3',
        subHeading: '#FFC107',
        sectionHeading: '#2196F3',

        filterBackground: '#2196F3',
        filterText: '#ffffff',
        activeFilter: '#272740',
        activeFilterBackground: '#FFC107',

        carauselButtons: '#2196F3',
        cardBackground: '#ffffff',
        primaryButtonBackground: '#2196F3',
        secondaryButtonBackground: '#FFC107',
        drawerBackground: '#1664a3'

    },
    teal: {
        primary: '#029c91',
        secondary: '#19746e',
        background: '#E3F2FD', // Light blue background
        secondaryBackground: '#12B25C',

        textColor: '#029c91',
        paragraphText: '#272740',

        navLinks: '#ffffff',
        navBackground: '#029c91',
        activeNavs: '#19746e',
        activeNavsLinks: '#ffffff',
        activeNavsShadow: 'rgba(0, 0, 0, 0.6)',

        scoreCardText1: '#ffffff',
        scoreCardText2: '#ffffff',
        scoreCardText3: '#ffffff',
        scoreCardText4: '#ffffff',
        scoreCardBackground1: '#00ACC1',
        scoreCardBackground2: '#FC9309',
        scoreCardBackground3: '#dd2466',
        scoreCardBackground4: '#59B15D',

        heading: '#029c91',
        subHeading: '#272740',
        sectionHeading: '#029c91',

        filterBackground: '#029c91',
        filterText: '#ffffff',
        activeFilterBackground: '#19746e',
        activeFilter: '#ffffff',

        carauselButtons: '#029c91',
        cardBackground: '#ffffff',
        primaryButtonBackground: '#029c91',
        secondaryButtonBackground: '#19746e',
        drawerBackground: '#19746e'
    },
    deamblue: {
        primary: '#1d2b69',
        secondary: '#1d2b69',
        background: '#7878d9',  //'#272791z', // Light blue background
        secondaryBackground: '#12B25C',

        textColor: '#FC9309',
        paragraphText: '#ffffff',
        // 
        navLinks: '#ffffff',
        navBackground: '#1d2b69',
        activeNavs: '#8ea0f6',
        activeNavsLinks: '#272740',
        activeNavsShadow: '#8EA0F2',

        scoreCardText1: '#ffffff',
        scoreCardText2: '#ffffff',
        scoreCardText3: '#ffffff',
        scoreCardText4: '#ffffff',
        scoreCardBackground1: '#00ACC1',
        scoreCardBackground2: '#FC9309',
        scoreCardBackground3: '#dd2466',
        scoreCardBackground4: '#59B15D',

        heading: '#ffffff',
        subHeading: '#ffffff',
        sectionHeading: '#ffffff',

        filterBackground: '#1d2b69',
        filterText: '#ffffff',
        activeFilterBackground: '#8ea0f6',
        activeFilter: '#272740',

        carauselButtons: '#ffffff',
        cardBackground: '#7781adfa',
        primaryButtonBackground: '#1d2b69',
        secondaryButtonBackground: '#1d2b69',
        drawerBackground: '#7878d9'

    },
    vividorange: {
        primary: '#EC9706',
        secondary: '#EC9706',
        background: '#f7ebd7', // Light blue background
        secondaryBackground: '#12B25C',

        textColor: '#1d2b69',
        paragraphText: '#1d2b69',
        // 
        navLinks: '#1d2b69',
        navBackground: '#EC9706',    //'#FCE6D3',
        activeNavs: '#E15F34',
        activeNavsLinks: '#ffffff',
        activeNavsShadow: '#EC9706',

        scoreCardText1: '#ffffff',
        scoreCardText2: '#ffffff',
        scoreCardText3: '#ffffff',
        scoreCardText4: '#ffffff',
        scoreCardBackground1: '#00ACC1',
        scoreCardBackground2: '#FC9309',
        scoreCardBackground3: '#dd2466',
        scoreCardBackground4: '#59B15D',

        heading: '#EC9706',
        subHeading: '#E15F34',
        sectionHeading: '#1d2b69',

        filterBackground: '#FCE6D3',
        filterText: '#EC9706',
        activeFilterBackground: '#E15F34', //'#EC9706',
        activeFilter: '#ffffff',

        carauselButtons: '#EC9706',
        cardBackground: '#E8D7BB ',
        primaryButtonBackground: '#EC9706',
        secondaryButtonBackground: '#E15F34',
        drawerBackground: '#5F5C57'

    },
    vividgreen: {
        primary: '#EC9706',
        secondary: '#EC9706',
        background: '#F5F6FA', // Light blue background
        secondaryBackground: '#12B25C',

        textColor: '#1d2b69',
        paragraphText: '#1d2b69',
        // 
        navLinks: '#1d2b69',
        navBackground: '#EC9706',    //'#FCE6D3',
        activeNavs: '#E15F34',
        activeNavsLinks: '#ffffff',
        activeNavsShadow: '#EC9706',

        scoreCardText1: '#ffffff',
        scoreCardText2: '#ffffff',
        scoreCardText3: '#ffffff',
        scoreCardText4: '#ffffff',
        scoreCardBackground1: '#00ACC1',
        scoreCardBackground2: '#FC9309',
        scoreCardBackground3: '#dd2466',
        scoreCardBackground4: '#59B15D',

        heading: '#EC9706',
        subHeading: '#E15F34',
        sectionHeading: '#1d2b69',

        filterBackground: '#FCE6D3',
        filterText: '#EC9706',
        activeFilterBackground: '#E15F34', //'#EC9706',
        activeFilter: '#ffffff',

        carauselButtons: '#EC9706',
        cardBackground: '#E8D7BB ',
        primaryButtonBackground: '#EC9706',
        secondaryButtonBackground: '#E15F34',
        drawerBackground: '#002147'

    }
};

export const createAppTheme = (color) =>
    createTheme({
        palette: {
            primary: {
                main: themeColors[color].primary,
            },
            secondary: {
                main: themeColors[color].secondary,
            },
            background: {
                default: themeColors[color].background,
            },
            secondaryBackground: {
                default: themeColors[color].secondaryBackground,
            },
            navLinks: {
                main: themeColors[color].navLinks,
            },

            // 
            navBackground: {
                main: themeColors[color].navBackground,
            },
            activeNavs: {
                main: themeColors[color].activeNavs,
            },
            activeNavsLinks: {
                main: themeColors[color].activeNavsLinks,
            },
            activeNavsShadow: {
                main: themeColors[color].activeNavsShadow,
            },
            scoreCardText1: {
                main: themeColors[color].scoreCardText1,
            },
            scoreCardText2: {
                main: themeColors[color].scoreCardText2,
            },
            scoreCardText3: {
                main: themeColors[color].scoreCardText3,
            },
            scoreCardText4: {
                main: themeColors[color].scoreCardText4,
            },
            scoreCardBackground1: {
                main: themeColors[color].scoreCardBackground1,
            },
            scoreCardBackground2: {
                main: themeColors[color].scoreCardBackground2,
            },
            scoreCardBackground3: {
                main: themeColors[color].scoreCardBackground3,
            },
            scoreCardBackground4: {
                main: themeColors[color].scoreCardBackground4,
            },
            heading: {
                main: themeColors[color].heading,
            },
            subHeading: {
                main: themeColors[color].subHeading,
            },
            sectionHeading: {
                main: themeColors[color].sectionHeading,
            },
            filterBackground: {
                main: themeColors[color].filterBackground,
            },
            filterText: {
                main: themeColors[color].filterText,
            },
            activeFilter: {
                main: themeColors[color].activeFilter,
            },
            activeFilterBackground: {
                main: themeColors[color].activeFilterBackground,
            },
            carauselButtons: {
                main: themeColors[color].carauselButtons,
            },
            textColor: {
                main: themeColors[color].textColor,
            },
            paragraphText: {
                main: themeColors[color].paragraphText,
            },
            cardBackground: {
                main: themeColors[color].cardBackground,
            },
            primaryButtonBackground: {
                main: themeColors[color].primaryButtonBackground,
            }, secondaryButtonBackground: {
                main: themeColors[color].secondaryButtonBackground,
            },
            drawerBackground: {
                main: themeColors[color].drawerBackground,
            }
        },
    });
