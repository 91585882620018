import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import styles from '../../styles/TestSeriesDetails.module.css';

export default function ScheduleTable() {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const tableHeaderStyle = {
        textAlign: 'center',
        padding: '8px',
        backgroundColor: '#f2f2f2',
        // fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: 'Poppins',
        border: '1px solid #ddd',
        // font-family: Poppins;
        // font-size: 18px;
    };

    const tableCellStyle = {
        textAlign: 'center',
        padding: '8px',
        border: '1px solid #ddd',
        fontSize: '18px',
        fontFamily: 'Poppins',
        color: ' #686262',
    };
    const closeButtonStyle = {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: ' #686262',
        textTransform: 'none'
    };
    const tableTitleStyles = {
        fontFamily: 'Poppins',
        fontSize: '18px',
        color: ' #686262',
        textTransform: 'none'
    }
    const seriesMockData = {
        tests: [
            { id: '101', name: 'Mock Test 1', date: '10/12/2024', time: '10:00 AM' },
            { id: '102', name: 'Mock Test 2', date: '12/15/2024', time: '2:00 PM' },
            // Add more mock data as needed
        ]
    };

    return (
        <div>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={tableHeaderStyle}>Test Id</th>
                        <th style={tableHeaderStyle}>Test Name</th>
                        <th style={tableHeaderStyle}>Date</th>
                        <th style={tableHeaderStyle}>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {seriesMockData.tests.map((test, index) => (
                        <tr key={index}>
                            <td style={tableCellStyle}>{test.id}</td>
                            <td style={tableCellStyle}>{test.name}</td>
                            <td style={tableCellStyle}>{test.date}</td>
                            <td style={tableCellStyle}>{test.time}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Button
                variant="outlined"
                // color="primary"
                onClick={handleClickOpen}
                className={styles.Rectangle39}
            // style={{ marginTop: '16px' }}
            >
                Learn More
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md" // You can adjust this value for more or less size
            >
                <DialogTitle sx={tableTitleStyles}>Test Series Schedules</DialogTitle>
                <DialogContent>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={tableHeaderStyle}>Test Id</th>
                                <th style={tableHeaderStyle}>Test Name</th>
                                <th style={tableHeaderStyle}>Date</th>
                                <th style={tableHeaderStyle}>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {seriesMockData.tests.map((test, index) => (
                                <tr key={index}>
                                    <td style={tableCellStyle}>{test.id}</td>
                                    <td style={tableCellStyle}>{test.name}</td>
                                    <td style={tableCellStyle}>{test.date}</td>
                                    <td style={tableCellStyle}>{test.time}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        sx={closeButtonStyle}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
