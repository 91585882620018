import axios from "axios";
import { useQuery } from "react-query";
import { configUrl } from "../config";

export default function useGetTestsCount() {
  return useQuery("testsCount", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Tests/getTestsCount`
    );
    return response.data;
  });
}
