import axios from "axios";
import { configUrl } from "../config";

const getUserMobileExists = async (mobile, providerId) => {
    console.log(mobile, 'mobile');
    return axios.get(`${configUrl.AssessmentServer}/api/Student/StudentMobileExists/${mobile}/${providerId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error?.response?.data, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status
            }
        });

};
export default getUserMobileExists;
