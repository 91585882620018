import CallMadeIcon from '@mui/icons-material/CallMade';
import GroupIcon from '@mui/icons-material/Group';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Box, Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import React from 'react';
import ReactHtmlParser from "react-html-parser";
import styles from '../styles/Home.module.css';

function TestSeriesCard({ title, description, image, bulletPoints, price, onLearnMore, onEnroll, testsCount, duration, testSeriesStudentsCount, studentsCount, isHideStudentIcon }) {
    const theme = useTheme();
    return (
        <Box sx={{ position: 'relative', margin: 2 }}>
            {/* Badge for New Program */}
            {/* <Box
                sx={{
                    position: 'absolute',
                    top: 10,
                    left: 10,
                    backgroundColor: theme.palette.secondary.main,
                    color: '#fff',
                    padding: '5px 10px',
                    borderRadius: '8px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    zIndex: 1,
                }}
            >
                New Program
            </Box> */}
            <Card className={styles.Rectangle8}>
                {/* Badge for New Program */}
                <Box
                    className={styles.newLaunchBadge}
                    sx={{
                        top: 165,
                        left: 25,
                    }}
                >
                    New Launch
                </Box>

                <div>
                    {image && <img src={image} alt={title} style={{ width: '100%', height: '200px ' }} />}
                </div>
                <CardContent style={{ height: '180px', cursor: 'context-menu' }}>
                    <Box>
                        <div className={styles.TestSeriesTitle}>{title}</div>
                        {/* <Typography variant="body2" color="text.secondary">{description}</Typography> */}

                        {/* Display up to 2 bullet points, with "..." if there are more */}
                        <Typography variant="body2" calssName={styles.cardDescription} style={{ listStyleType: 'disc', fontSize: '14px', textAlign: 'left', height: '70px' }}>
                            {description ? ReactHtmlParser(description?.slice(0, 109)) : "No description available."}
                            {/* {bulletPoints && bulletPoints.slice(0, 2).map((point, index) => (
                            <li key={index} style={{ margin: '10px 0px' }}>{point}</li>
                        ))} */}

                            {/* {bulletPoints.length > 2 && <li>...</li>} // can display "..." if more than 2 points */}
                        </Typography>
                    </Box>
                    {/* <div className={styles.Line9}></div> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', borderTop: '1px solid #cacaca' }}>
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}> */}
                        <span className={styles.price}>{isHideStudentIcon ? "" : <GroupIcon className={styles.peoples} />} {testSeriesStudentsCount}</span>
                        {/* <p style={{ margin: '7% 6% 0% 6%', fontSize: '13px', color: theme.palette.sectionHeading.main }}>{duration ?? 0}{` Months`}</p> */}

                        {/* Price on the left side */}
                        <span className={styles.price}>
                            <LocalOfferIcon className={styles.priceTag} /> {`₹ ${price || 0}`}
                        </span>

                        {/* Learn More button on the right side */}
                        {/* <Button size="small" onClick={onLearnMore} style={{ float: 'right', marginTop: '10px' }}>Learn More</Button> */}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}> */}
                        {/* <p style={{ margin: '3% 6% 0% 3%', fontSize: '17px', color: theme.palette.secondary.main }}>{testsCount} Tests</p> */}
                        <Button className={styles.Rectangle24} onClick={onLearnMore}>
                            {/* {`₹ ${price || 0}`} */}
                            Know More
                        </Button>
                        {/* <p style={{ margin: '7% 6% 0% 6%', fontSize: '13px', color: theme.palette.sectionHeading.main }}>{duration ?? 0}{` Months`}</p> */}

                        {/* Price on the left side */}
                        {/* <Typography variant="h6" component="div" style={{ margin: '2% 6%  0% 5%', color: theme.palette.primary.main, fontSize: '20px' }}>
                        {`₹ ${price || 0}`}
                    </Typography> */}

                        {/* Learn More button on the right side */}
                        {/* <Button size="small" onClick={onLearnMore} style={{ float: 'right', marginTop: '10px' }}>Learn More</Button> */}
                        <Button className={styles.Rectangle25} onClick={onEnroll}>
                            {/* {`₹ ${price || 0}`} */}
                            Buy Now  <CallMadeIcon />
                        </Button>
                    </Box>
                </CardContent >
            </Card >
        </Box >
    );
}

export default TestSeriesCard;
