import { GoogleLogin } from '@react-oauth/google';
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React from 'react';
import { useParams } from "react-router-dom";
import { configUrl } from "../../config";
import userService from "../../services/userService";

const Google = (props) => {
    const { pKey } = useParams();
    let providerId = 0;
    console.log('google-login : ' + props.providerId)
    if (props.providerId) {

        providerId = props.providerId;
    }

    let defaultPassword = "Abc@1234";
    return (
        <GoogleLogin
            onSuccess={async (credentialResponse) => {

                if (credentialResponse && credentialResponse.credential) {
                    const decoded = jwtDecode(credentialResponse.credential);

                    console.log('decoded', decoded, 'provider', providerId)
                    console.log('decoded email ', decoded.email)
                    // CommonUrl-
                    // axios.get(`${configUrl.AssessmentServer}/api/User/Name/${decoded.email}/${providerId}`)
                    axios.get(`${configUrl.AssessmentServer}/api/User/Name/${decoded.email}`)
                        .then(response => {
                            // Handle response
                            console.log('response log: ', response.data);
                            console.log('user exist will login')
                            props.onClick(decoded.email, true, null)
                        })
                        .catch(async err => {
                            // Handle errors
                            if (err.response.data.status == '404') {

                                if (configUrl.MediNeerProviderId) {
                                    providerId = configUrl.MediNeerProviderId
                                }
                                let newUser = {
                                    userName: decoded.email,
                                    firstName: decoded.given_name,
                                    lastName: decoded.family_name,
                                    fullName: decoded.given_name + decoded.family_name,
                                    middleName: '',
                                    mobile: '',
                                    email: decoded.email,
                                    passCode: defaultPassword,
                                    address: '',
                                    isActive: true,
                                    userTypeId: 4,
                                    topics: "",
                                    createdBy: 'system',
                                    createdDate: new Date(),
                                    updatedBy: 'system',
                                    updatedDate: new Date(),
                                    providerId: providerId,
                                }
                                console.log('will add new user', newUser)
                                await userService.createUser(newUser)
                                console.log('user added')
                                props.onClick(decoded.email, true, newUser)
                            }
                        });

                }
            }}
            onError={() => {
                props.onClick('', false, null)
                console.log('Login Failed');
            }}
        />
    )
}
export default Google;