import axios from "axios";
import CryptoJS from 'crypto-js';
import { useContext } from "react";
import { useMutation } from "react-query";
import { LoginContext } from "../contexts/LoginContext";

export default function useGetMedineerUser() {

    const { config } = useContext(LoginContext)

    const key = 'pj23vs7nycq18uew';
    let secretKey = CryptoJS.enc.Utf8.parse(key);

    const encryptPassword = (password, secretKey) => {

        let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let encryptedString = encryptedBytes.toString();

        console.log(encryptedString, 'encryptedone');
        return encryptedString;
        // return CryptoJS.AES.encrypt(password, secretKey).toString();
    }
    const decryptPassword = (encryptedPassword, secretKey) => {

        let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

        console.log(decryptedText, 'decryptedone');
        return decryptedText;
        // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
        // return bytes.toString(CryptoJS.enc.Utf8);
    }

    return useMutation(async (loginInfo) => {
        try {
            console.log(loginInfo, "loginInfo");
            const isMedinerLogin = loginInfo.isMedinerLogin ?? false;
            const courseId = loginInfo?.courseId ?? 0;
            let response = await axios.get(
                `${config.api}/api/User/Name/${loginInfo.username}/${0}?isMedineerLogin=${isMedinerLogin}&&courseId=${courseId}`  //Common Url - check diff 18/05/2024

            );
            console.log(response, "getUserbyUsername.....")
            //alert(response.data)

            let userDetails = response.data

            if (!userDetails) {
                throw 'Not Found'
            }
            response.data.userDetails.passCode = decryptPassword(response?.data?.userDetails?.passCode, secretKey)
            return response.data;

        } catch (error) {
            return 'error ' + error
        }
    })
}
