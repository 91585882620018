import { makeStyles } from "@material-ui/core/styles";
import { BookmarkBorder, BookmarkOutlined } from "@material-ui/icons";
import { useTheme } from "@mui/material";
import React, { useContext } from "react";
import { TestContext } from "../contexts/TestContext";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function Bookmark({ question }) {

  const { setMarForkReview, setSelectEvent, selectEvent } = useContext(TestContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const handlePopoverOpen = (event) => {
    setSelectEvent(selectEvent == true ? false : true)
    setAnchorEl(event.currentTarget);
    setMarForkReview(event.currentTarget)
    question.markForReview = !question.markForReview
    if (question.markForReview) {

      question.markForReviewCount = question.markForReviewCount + 1;
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {!question.markForReview ?
        <BookmarkBorder
          style={{ color: theme.palette.primary.main, fontSize: 30 }}
          onClick={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        /> :
        <BookmarkOutlined
          style={{ color: theme.palette.primary.main, fontSize: 30 }}
          onClick={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      }
    </>
  );
}
