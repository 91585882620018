import axios from "axios";
import { useQuery } from "react-query";
import { configUrl } from "../config";


export default function useGetAllObjective(configType, isActive, isContentProvider, isProviderName) {

    const { providerType } = 3;
    return useQuery("GetAllObjective", async () => {
        if (configType == null) {
            console.log(configType);
            return null;
        }
        if (isActive == undefined || isActive == "") {
            isActive = false;
        }
        if ((providerType == 1 || providerType == 3) && isContentProvider != false) {
            isContentProvider = true;
        }
        if (isContentProvider == undefined) {
            isContentProvider = false;
        }
        let response = await axios.get(
            `${configUrl.AssessmentServer}/api/DemoghraphicInformation/${configType}/${isContentProvider}/${isActive}/${isProviderName}`
        );
        return response.data["$values"];
    },
        {
            staleTime: 1000 * 60 * 5,
        });
}
