import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Grid, Pagination, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactHtmlParser from "react-html-parser";
import { useLocation, useNavigate } from 'react-router-dom';
import ScheduleTable from '../Components/MedineerComponents/ScheduleTable';
import { configUrl } from '../config';
import { LoginContext } from '../contexts/LoginContext';
import getProviderContentCuratorIds from '../Hooks/useGetProviderContentCuratorIds';
import styles from '../styles/TestSeriesDetails.module.css';
import Header from './Header';
import TestSeriesCard from './TestSeriesCard';

function TestSeriesDetails({ testSerieses, onBack }) {
    const location = useLocation();
    const scrollRef = useRef(null);
    const [testSeries, setTestSeries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize] = useState(3);
    const [totalPages, setTotalPages] = useState(1);
    const { contentCuratorIds, setContentCuratorIds } = useContext(LoginContext);

    const fetch = async () => {
        try {
            let ids;
            if (contentCuratorIds.length <= 0) {
                const data = await getProviderContentCuratorIds(configUrl.MediNeerProviderId);
                ids = data?.$values || [];
            }
            axios.defaults.headers.common = {
                providerId: configUrl.MediNeerProviderId,
                contentProviderIds: ids ?? contentCuratorIds
            };
            setContentCuratorIds(ids ?? contentCuratorIds);
        } catch (error) {
            console.log("error getting contentcuratorids");
        }
    };
    const fetchTests = async (page, objective) => {
        setLoading(true);
        await fetch();
        try {
            const response = await axios.get(
                `${configUrl.AssessmentServer}/api/Courses`,
                {
                    params: {
                        pageNumber: page,
                        pageSize: pageSize,
                        contentFilter: 0,
                        contentCuratorIds: null,
                        objective: objective || null,
                    },
                }
            );
            const fetchedTests = response?.data?.$values || [];
            const totalItems = (response?.data?.$values && response?.data?.$values?.length > 0 && response?.data?.$values[0]?.totalCount) || 0;
            setTestSeries(fetchedTests);
            setTotalPages(Math.ceil(totalItems / pageSize));
            console.log("toatalcount", Math.ceil(totalItems / pageSize), totalItems)
        } catch (error) {
            console.error("Error fetching test series:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setTestSeries([]); // Clear previous series
        fetchTests(1, null);
        if (pageNumber !== 1) {
            setPageNumber(1);
        }
    }, [pageNumber]);

    console.log("testsata", testSerieses);
    const { series } = testSerieses || location.state; // will pass actul dta from api Retrieve series data from location state will 
    console.log("serie12", series);
    const theme = useTheme();
    const navigate = useNavigate();
    const seriesData = {
        overView: `The ${series?.text || series?.courseName} Test Series is designed to enhance problem-solving skills and mirror the real exam environment. With a focus on the latest JEE Main format, these tests help you track your preparation and identify areas for improvement.`,
        includes: [
            "JEE Main Topicwise Test",
            "JEE Main Guided Revision Test (For Jan 2021 Attempt)",
            "JEE Main Guided Revision Test (For Jan and Apr 2021 Attempt)",
            "JEE Main + Advanced Guided Revision Test",
            "JEE Advanced Topicwise Test"
        ]
    };
    const handlePageChange = (event, value) => {
        setPageNumber(value);
    };
    const handleLearnMore = (series) => {
        navigate('/medineer/test-series-details', { state: { series } });
    };
    const onEnroll = (series) => {
        navigate('/medineer/SignUp', { state: { series } });
    }
    return (
        <>
            <Header isTransperant={false} />
            <Box className={styles.defaultBackground}>
                <Grid container className={styles.Rectangle5} justifyContent={'center'}>
                    {/* Main Course Title Section */}
                    <Grid item xs={12} sm={7} sx={{ textAlign: 'left' }}>
                        <Grid container justifyContent={'left'}>
                            <Grid item xs={12} sm={11}>
                                <div className={styles.testSeriesHeader}>
                                    {series?.text || series?.courseName}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={11}>
                                {/* <Typography variant="h5" className={styles.testSeriesHeader} fontSize={'24px'} gutterBottom> */}
                                <div className={styles.TestSeriesDescription}>
                                    {/* {seriesData.overView} */}
                                    {console.log("dscription", series?.description, series)}
                                    {ReactHtmlParser(series?.description)}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'left'}>
                            <Grid item xs={12} sm={4}>
                                <div className={styles.Rectangle27}>
                                    {/* <div className={styles.Vector}> */}
                                    <span>
                                        <img
                                            src="/Icon - number of test.png"
                                            alt="icon2"
                                            className={styles.Vector}
                                        />
                                        <span className={styles.NumberofTests}>
                                            {/* </div> */}
                                            Number of Tests:<span style={{ fontWeight: 'bold' }}> {`${series?.testCount ?? '0'} +`}</span>
                                        </span>
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div className={styles.Rectangle28}>
                                    <img
                                        src="/Icon - number of students.png"
                                        alt='icon3'
                                        className={styles.Vector}
                                    />
                                    <span className={styles.NumberofStudentsEnrolled}>
                                        {
                                            series?.assingnedStudentsCount < 10 ?
                                                'Newly launched' :
                                                <>
                                                    Number of Students Enrolled: <span style={{ fontWeight: 'bold' }}> {series?.assingnedStudentsCount ?? 0}</span>
                                                </>
                                        }
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'left'}>
                            <Grid item xs={12} sm={3}>
                                <div className={styles.Rectangle29}>
                                    {/* <span> */}
                                    <img
                                        src="/Icon - price.png"
                                        alt="icon2"
                                        className={styles.Vector}
                                    />
                                    <span className={styles.price}>
                                        {`₹ ${series?.price || '0'}`}
                                    </span>
                                    {/* </span> */}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={styles.Rectangle30}>
                                    <span>
                                        <img
                                            src="/Icon - duration.png"
                                            alt="icon2"
                                            className={styles.Vector}
                                        />
                                        <span className={styles.Duration}>
                                            Duration: <span style={{ fontWeight: 'bold' }}>
                                                {
                                                    series?.duration ?
                                                        `${series?.duration} Months` : ''
                                                }
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={styles.Rectangle31}>
                                    <span>
                                        <img
                                            src="/icon - validity.png"
                                            alt="icon2"
                                            className={styles.Vector}
                                        />
                                        <span className={styles.ValidUntil}>
                                            Valid Until :
                                        </span>
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Course Image and Details Section */}
                    <Grid item xs={12} sm={4} style={{ position: 'relative' }}>
                        <img
                            src={series?.image || '/Gemini_image_not_available.jpg'}
                            alt={series?.text || series?.courseName}
                            style={{ width: '100%', height: 'auto', borderRadius: 8, marginTop: '5rem' }}
                        />
                        {/* Price Badge */}
                        <Box
                            className={styles.priceBadge1}
                        >
                            ₹ {series?.price || '0'}
                        </Box>
                        <Button
                            variant="contained"
                            fullWidth
                            className={styles.Rectangle25}
                            onClick={() => {
                                navigate('/medineer/SignUp', { state: { series } });
                            }}
                            style={{ marginTop: '1rem' }}
                        >
                            Buy Now
                            <img
                                src="/IconSchedule arrow.png"
                                alt="icon2"
                                className={styles.VectorArrow}
                            />
                        </Button>
                    </Grid>

                </Grid>

                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} sm={11}>
                        <div className={styles.Schedule}>
                            Schedule
                            <img
                                src="/IconSchedule arrow.png"
                                alt="icon2"
                                style={{ marginLeft: '1rem' }}
                            // className={styles.VectorArrow}
                            />
                        </div>
                        <div className={styles.ScheduleText}>
                            Although these test schedules have already passed, you may take them at your convenience and align them with the upcoming test schedule.
                        </div>
                    </Grid>
                </Grid>
                {/* Collapsible Sections */}
                <Grid container justifyContent={'center'} sx={{ marginTop: 4, paddingX: 2 }}>
                    <Grid item xs={12} sm={11}>
                        <Box sx={{ marginBottom: 2 }}>
                            {/* Overview Section */}
                            <Accordion>
                                <AccordionSummary className={styles.AccordianSummary} expandIcon={<PlayArrowIcon className={styles.AccordianArrow} />}>
                                    <Typography className={styles.PassedSchedule}>
                                        Passed Schedule
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ borderTop: '1px solid #c6c6c6' }}>
                                    <Typography sx={{
                                        fontFamily: 'Poppins',
                                        fontSize: '18px',
                                        color: '#686262',
                                        textAlign: 'left',
                                    }}>{seriesData.overView}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        {/* Includes Section */}
                        <Box sx={{ marginBottom: 2 }}>
                            <Accordion>
                                <AccordionSummary className={styles.AccordianSummary} expandIcon={<PlayArrowIcon className={styles.AccordianArrow} />}>
                                    <Typography className={styles.PassedSchedule}>
                                        Upcoming  Schedule
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ borderTop: '1px solid #c6c6c6' }}>
                                    <Box sx={{ overflowX: 'auto', marginTop: 2 }}>
                                        <ScheduleTable />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} sx={{ backgroundColor: '#fff' }}>
                    <Grid item xs={12} sm={11}>
                        <div className={styles.Schedule}>
                            Explore our other courses
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Box ref={scrollRef} sx={{ display: 'flex', overflowX: 'auto', scrollBehavior: 'smooth', '&::-webkit-scrollbar': { display: 'none' } }}>
                            {loading ? (
                                <Box sx={{ flex: '0 0 auto', width: 400, height: 300, m: 1, justifyContent: 'center' }}>
                                    <CircularProgress
                                        style={{ marginLeft: '600px', marginTop: '110px' }}
                                    />
                                </Box>
                            )
                                : (
                                    testSeries && testSeries?.length > 0 ? (testSeries.map((series, index) => (
                                        <Box key={index} sx={{ flex: '0 0 auto', width: 400, m: 1 }}>
                                            <TestSeriesCard
                                                title={series?.text || series?.courseName}
                                                description={series?.description}
                                                image={series?.image ?? '/Gemini_image_not_available.jpg'}
                                                bulletPoints={series?.bulletPoints}
                                                price={series?.price || 0}
                                                onLearnMore={() => handleLearnMore(series)}
                                                onEnroll={() => onEnroll(series)}
                                                testsCount={series?.testCount || 0}
                                                duration={series?.duration}
                                                courseId={series?.courseId}
                                            />
                                        </Box>
                                    )))
                                        : (
                                            <Box sx={{ flex: '0 0 auto', width: '700px', height: '300px', m: 1, justifyContent: 'center' }}>
                                                <h4 style={{ marginLeft: '350px', marginTop: '70px' }}>No tests series found</h4>
                                            </Box>)
                                )}
                        </Box>
                        <Box display="flex" justifyContent="center" mt={2}>
                            {totalPages > 1 && (
                                <Pagination
                                    count={totalPages}
                                    page={pageNumber}
                                    onChange={handlePageChange}
                                    // color="primary"
                                    sx={{
                                        '& .MuiPaginationItem-root': {
                                            color: '#002147', // Default text color
                                        },
                                        '& .MuiPaginationItem-root.Mui-selected': {
                                            backgroundColor: '#002147', // Selected background color
                                            color: 'white', // Text color for the selected page
                                        },
                                        '& .MuiPaginationItem-ellipsis': {
                                            color: '#002147', // Dots color
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default TestSeriesDetails;
