
function BlockShortcuts() {
    const handleKeyDown = (event) => {
        const blockedKeys = [13, 18, 27, 8]; // Enter, Alt, Esc, Backspace key codes
        if (blockedKeys.includes(event.keyCode)) {
            event.preventDefault();
        }
    };

    return {
        handleKeyDown
    };
}
export default BlockShortcuts