import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../Card/Card';
import Charts from '../Chart/Charts';
import GridContainer from '../Components/Grid/GridContainer';
import GridItem from '../Components/Grid/GridItem';
import AnalyticsTable from '../Tables/AnalyticsTable';
import ChartTable from '../Tables/ChartTable';
import { LoginContext } from '../contexts/LoginContext';
import styles from '../styles/DetailingTable.module.css';
import UseGetAnalytics2D from './analyticsHooks/UseGetAnalytics2D';
import UseGetGraph from './analyticsHooks/UseGetGraphAnalytics';

export default function Analytics({ lOid, studentId }) {

  const [contentId, setContentId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [subTypeId, setSubTypeId] = useState(1);
  const [graphAnalyticsData, setGraphAnalyticsData] = useState();
  const { packageId } = useContext(LoginContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const getGraphData = async () => {
    setIsLoading(true);
    const testany = await UseGetGraph(studentId ? studentId : null, lOid)
    setGraphAnalyticsData(testany);
    setIsLoading(false);
  }
  useEffect(() => {
    getGraphData();
  }, [])
  //, isLoading: isLoadingAnalytics
  const { data, isLoading: isLoadingAnalytics } = UseGetAnalytics2D(contentId, subTypeId, studentId, lOid);

  const handleContentChange = (event) => {
    setContentId(event.target.value);
  };
  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const handleExecutionChange = (event) => {
    setSubTypeId(event.target.value);
  };
  console.log(graphAnalyticsData, 'findingOjbs')
  console.log(packageId, 'packageId1')

  // BSS121 will need to take a change like whrough 400 Bad Request
  let analyticsResultErr = "No Record Found"
  return (
    <GridContainer className={styles.container}>

      {
        graphAnalyticsData && graphAnalyticsData?.analyticsResult != analyticsResultErr && isLoading == false ?
          <>
            <GridItem xs={12} sm={12} md={6} lg={4}>
              <h4 className={styles.tablefont} style={{ color: theme.palette.heading.main }}>{t("objective")} : <span style={{ color: 'gray' }}>{graphAnalyticsData && graphAnalyticsData?.objective?.trim().length == 0 ? 'none' : graphAnalyticsData?.objective}</span></h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
              <h4 className={styles.tablefont} style={{ color: theme.palette.heading.main }}>{t("targetAudience")} : <span style={{ color: 'gray' }}>{graphAnalyticsData && graphAnalyticsData?.targetAudience?.trim().length == 0 ? 'none' : graphAnalyticsData?.targetAudience}</span></h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
              <h4 className={styles.tablefont} style={{ color: theme.palette.heading.main, float: 'right' }}>{t("testResult")} : <span style={{ color: 'gray' }}>{graphAnalyticsData && graphAnalyticsData?.totalScore == 0 ? 'none' : `${graphAnalyticsData?.actualScore} / ${graphAnalyticsData?.totalScore}`}</span></h4>
            </GridItem>
            {/* chart Table */}
            <GridItem xs={12} sm={12} md={6} lg={5}>
              <Card style={{ cursor: "pointer" }}>
                <ChartTable
                  studentId={studentId}
                  lOid={lOid}
                  analyticsData={graphAnalyticsData && graphAnalyticsData?.analyticsResult ? isJSON(graphAnalyticsData?.analyticsResult) ? JSON.parse(graphAnalyticsData?.analyticsResult) : '' : ''}
                />
              </Card>
            </GridItem>
            {/* Chart  */}
            <GridItem xs={12} sm={12} md={6} lg={5}>
              <Card style={{ cursor: "pointer" }}>
                <Charts
                  studentId={studentId}
                  lOid={lOid}
                  analyticsData={graphAnalyticsData && graphAnalyticsData?.analyticsResult ? isJSON(graphAnalyticsData?.analyticsResult) ? JSON.parse(graphAnalyticsData?.analyticsResult) : '' : ''}
                />
              </Card>

            </GridItem>
            {/* analytics Table */}
            <GridItem styles={{ marginBottom: '5px' }}>
              <h6>{t('analyticsNote')}</h6>
            </GridItem>
            <GridItem xs={12} sm={12} md={10} lg={9}>
              <Box sx={{ minWidth: 100 }}>
                <FormControl style={{ width: "47%" }}>
                  <InputLabel className={styles.tablefont} style={{ color: theme.palette.heading.main }} id="demo-simple-select">{t("content")}</InputLabel>
                  <Select
                    className={styles.tablefont}
                    labelId="demo-simple-select"
                    id="demo-simple-select"
                    value={contentId}
                    label="Content"
                    onChange={handleContentChange}
                  >
                    <MenuItem value={1}>{t("categorySubject")}</MenuItem>
                    <MenuItem value={2}>{t("dificultyLevel")}</MenuItem>
                    <MenuItem value={3}>{t("questionType")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{ float: 'right', width: "47%" }}>
                  <InputLabel className={styles.tablefont} style={{ color: theme.palette.heading.main }} id="demo-simple-select-label">{t("execution")}</InputLabel>
                  <Select
                    className={styles.tablefont}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={subTypeId}
                    label="Execution"
                    onChange={handleExecutionChange}
                  >
                    {
                      packageId == 1 || packageId == 2 || packageId == 3 ?
                        <MenuItem value={1}>{t("score")}</MenuItem> : null

                    }
                    {
                      packageId == 2 || packageId == 3 ?
                        <MenuItem value={2}>{t("questionAttemptRate")}</MenuItem> : null
                    }
                    {packageId == 2 || packageId == 3 ?
                      <MenuItem value={3}>{t("accuracyRate")}</MenuItem> : null
                    }
                    {
                      packageId == 1 || packageId == 2 || packageId == 3 ?
                        <MenuItem value={4}>{t("duration")}</MenuItem> : null
                    }
                  </Select>
                </FormControl>
              </Box>
              <Card style={{ cursor: "pointer" }}>
                {data && data?.analyticsResult != analyticsResultErr && isLoadingAnalytics == false ?
                  <AnalyticsTable
                    analyticsData={data && data?.analyticsResult != analyticsResultErr ? JSON.parse(data?.analyticsResult) : ''}
                  />
                  : <CircularProgress
                    style={{ size: "1rem", color: theme.palette.heading.main, marginLeft: '400px', marginTop: '10px' }}
                  />
                }
              </Card>
            </GridItem>
          </>
          :
          graphAnalyticsData?.analyticsResult == "No Record Found" ?

            <Card style={{ cursor: "pointer" }}>
              <h5 style={{ textAlign: 'center', fontSize: 'large' }}>{t('pleaseWaitAnalytics')}</h5>
            </Card>
            : isLoading == true ?
              <CircularProgress
                style={{ size: "1rem", color: theme.palette.heading.main, position: 'relative', marginLeft: '600px', marginTop: '300px' }}
              />
              :
              <Card style={{ cursor: "pointer" }}>
                <h5 style={{ textAlign: 'center', fontSize: 'large' }}>{t('pleaseWaitAnalytics')}</h5>
              </Card>
      }
    </GridContainer>
  )
}




