
import axios from "axios";
import { configUrl } from "../config";

const verifyPayment = async (paymentData) => {
    console.log(paymentData, 'paymentData')
    return axios.post(`${configUrl.AssessmentServer}/api/Payments/verifyPayment`, paymentData)
        .then((response) => {
            console.log("Payment verification success:", response.data);
            return response;
        })
        .catch((error) => {
            console.error("Payment verification failed:", error);
            return error;
        });

};
export default verifyPayment;
