import { Card } from "@mui/material";
import React from "react";

import styles from '../../styles/Home.module.css';

const FeedbackCard = ({ studentName, feedbackText, studentImage }) => {
    return (
        <Card
            sx={{
                maxWidth: 600,
                minHeight: 265,
                padding: '30px',
                margin: "20px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
        >
            <div className={styles.Rectangle19span}>
                ‘‘
            </div>
            <div className={styles.stuentFeedback}>
                " {feedbackText} "
                <span className={styles.textStudentName}>{`-[ ${studentName} ]`}</span>
            </div>
        </Card>
    );
};
export default FeedbackCard;