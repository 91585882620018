import axios from "axios";
import { configUrl } from "../config";

const applyCoupon = async (code) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/Coupon/ApplyCoupon/${code}`
    );
    console.log(response.data, 'responseData')
    return response.data;
};
export default {
    applyCoupon: applyCoupon,
}