import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, CircularProgress, IconButton, Modal, Typography } from '@mui/material';
import { message } from 'antd';
import React, { useContext, useState } from 'react';
import { LoginContext } from '../../contexts/LoginContext';
import verifyPayment from '../../Hooks/verifyPayment';
import CheckoutPage from '../CheckoutPage';

const MedineerLogin = ({ course, onProceedToPayment, existingUserEmail, closeModal }) => {

    const { providerId, UserId } = useContext(LoginContext);
    const [showModal, setShowModal] = useState(true); // Modal opens by default
    const [loading, setLoading] = useState(false);


    console.log("coursedata", course, UserId);

    const verify = async (couponData) => {
        setLoading(true);
        console.log("coupdadat", couponData);
        //console.log("paymentreponse", response);
        // const paymentData = {
        //   razorpay_order_id: response.razorpay_order_id,
        //   razorpay_payment_id: response.razorpay_payment_id,
        //   razorpay_signature: response.razorpay_signature,
        // };

        const paymentData = {
            razorpayOrderId: "abcd123445",
            razorpayPaymentId: "abcd123445",
            razorpaySignature: "abcd123445",
        };

        const finalAmount = couponData?.finalPrice == null ? course?.price : couponData?.finalPrice;
        const paymentInfo = {
            providerId: providerId,
            courseId: course?.courseId,  //courseId;,
            price: finalAmount || 0,//100, //price;,
            paymentMethod: "UPI",
            couponCodeId: couponData?.couponID
        }
        console.log("userdata", UserId, existingUserEmail);
        const medineerUserId = existingUserEmail ? 0 : UserId?.length > 0 ? UserId[0] : UserId;
        const studentInfo = { userId: medineerUserId, email: existingUserEmail }
        const educationInfo = { userId: 0 };
        let request = {};
        request.studetnInfo = studentInfo //userDto;
        request.paymentVerification = paymentData;
        request.paymentInfo = paymentInfo;
        request.educationInfo = educationInfo;

        console.log("data", request);
        try {
            const verificationResponse = await verifyPayment(request);

            console.log("response", verificationResponse);
            if (verificationResponse?.data && verificationResponse.data?.message) {
                setTimeout(() => {
                    message.success("Payment successful.You will get course assign within next 1 hour.");
                }, 2000);
            } else {
                message.error("Failed to register and create order.");
            }
        } catch (error) {
            console.log("error", error);
            message.error("Failed to register and create order.");
        } finally {
            console.log("in finally");
            setLoading(false);
            handleCloseModal();
        }
    }
    const handleCloseModal = () => {
        closeModal();
        setShowModal(false);
    };

    return (
        <>
            <Backdrop
                open={loading}
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.modal + 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress size={60} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Processing your request, please wait...
                </Typography>
            </Backdrop>
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="checkout-modal-title"
                aria-describedby="checkout-modal-description"
                disableEnforceFocus
                disableScrollLock
                BackdropProps={{
                    style: { backgroundColor: 'transparent' },
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '600px',
                        bgcolor: 'background.paper',
                        p: 4,
                        borderRadius: 2,
                        boxShadow: 'none',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            zIndex: 10,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography
                        id="checkout-modal-title"
                        variant="h4"
                        align="center"
                        gutterBottom
                    >
                        Checkout
                    </Typography>
                    <CheckoutPage
                        course={course}
                        onProceedToPayment={(couponData) => {
                            console.log("coupdfirst", couponData);
                            verify(couponData);
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default MedineerLogin;
