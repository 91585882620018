import { Box, Button, CircularProgress, Divider, Grid, IconButton, Pagination, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import { ReactComponent as BookIcon } from '../icons/book.svg'; // Sample SVG icon
// import { ReactComponent as GraduationIcon } from '../icons/graduation-cap.svg';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Carousel, message } from 'antd';
import axios from 'axios';
import FeedbackCard from '../Components/MedineerComponents/FeedbackCards';
import { configUrl } from '../config';
import { LoginContext } from '../contexts/LoginContext';
import useGetCourseCount from '../courseHooks/useGetCourseCount';
import useGetAllObjective from '../Hooks/useGetAllObjective';
import getProviderContentCuratorIds from '../Hooks/useGetProviderContentCuratorIds';
import useGetTestsCount from '../Hooks/useGetTestsCount';
import styles from '../styles/Home.module.css';
import Header from './Header';
import TestSeriesCard from './TestSeriesCard';

const carouselImages = [
  {
    id: 1,
    imageUrl: "header1.jpg", // Adjust the path based on your setup
    title: "Slide One",
    description: "This is the description for slide one.",
  },
  {
    id: 2,
    imageUrl: "header1.jpg",
    title: "Slide Two",
    description: "This is the description for slide two.",
  },
  {
    id: 3,
    imageUrl: "header1.jpg",
    title: "Slide Three",
    description: "This is the description for slide three.",
  },
  // '/accordian1.png',
  // '/accordian2.jpg',
  // '/accordian3.jpg',

];



// const studentCount = 1200;
// const testCount = 45;
// const testSeriesCount = 10;
// const questionsCount = 24000;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
};
const testSeriesDataDetails = [
  { title: 'JEE Main Test Series 1 - 2025', testsCount: '10', duration: '3 Months', description: 'Description for Series 1', image: '/OIP4.jpeg', bulletPoints: ['School like discipline and homework', 'Daily Doubt Solving with teachers', 'Closest to actual exam, most relevant', 'Practiced by more than 1500+ IITians in 2024'], price: '₹ 990' },
  { title: 'JEE Main Test Series 2 - 2024', testsCount: '09', duration: '6 Months', description: 'Description for Series 2', image: '/online1.jpg', bulletPoints: ['All India Test Series with analysis', 'Most affordable', 'Prizes worth Rs 3 lakh', 'Practiced by more than 1500+ IITians in 2024', '1-1 Mentoring sessions for students'], price: '₹ 899' },
  { title: 'JEE Main Test Series 3 - 2025', testsCount: '14', duration: '5 Months', description: 'Description for Series 3', image: '/OIP3.jpeg', bulletPoints: ['All India Test Series with analysis', 'Maximum number of tests', 'Practiced by more than 1500+ IITians in 2024', '1-1 Mentoring sessions for students'], price: '₹ 796' },
  { title: 'JEE Main Test Series 4 - 2025', testsCount: '18', duration: '2 Months', description: 'Description for Series 4', image: '/online2.png', bulletPoints: ['School like discipline and homework', 'Prizes worth Rs 3 lakh', 'Daily Doubt Solving with teachers', 'Based on new pattern'], price: '₹ 693' },
  { title: 'JEE Main Test Series 5 - 2025', testsCount: '20', duration: '3 Months', description: 'Description for Series 4', image: '/OIP.jpg', bulletPoints: ['School like discipline and homework', 'Daily Doubt Solving with teachers', 'Practiced by more than 1500+ IITians in 2024', 'Based on new pattern'], price: '₹ 899' },
  { title: 'JEE Main Test Series 6 - 2025', testsCount: '50', duration: '7 Months', description: 'Description for Series 4', image: '/JEE1.png', bulletPoints: ['School like discipline and homework', 'Daily Doubt Solving with teachers', 'Practiced by more than 1500+ IITians in 2024', 'Based on new pattern'], price: '₹ 591' },
];

function Home() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(1); // active card index
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(3);
  const [totalPages, setTotalPages] = useState(1);
  const [testSeries, setTestSeries] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [objectiveFlag, setObjectiveFlag] = useState(null);

  const { contentCuratorIds, setContentCuratorIds } = useContext(LoginContext);

  const fetch = async () => {
    try {
      let ids;
      if (contentCuratorIds.length <= 0) {
        const data = await getProviderContentCuratorIds(configUrl.MediNeerProviderId);
        ids = data?.$values || [];
      }
      axios.defaults.headers.common = {
        providerId: configUrl.MediNeerProviderId,
        contentProviderIds: ids ?? contentCuratorIds
      };
      setContentCuratorIds(ids ?? contentCuratorIds);
    } catch (error) {
      console.log("error getting contentcuratorids");
    }
  };

  console.log("contenturatid", contentCuratorIds);

  // const { data: testSeriesCount } = useGetAssignedCoursesCount();

  // const { data: testCount } = useGetAssignedTestsCount();

  const { data: testSeriesCount } = useGetCourseCount();

  const { data: testCount } = useGetTestsCount();

  // const { data: questionsCount } = useGetQuestionsCount();
  // const { data: studentCount } = useGetAllUserCount();


  const handleNavigation = (series, index) => {
    // navigate(route);
    console.log("Series", series, index);
    if (objectiveFlag == series?.id) {
      setActiveButtonIndex(null);
      setObjectiveFlag(null);
    } else {
      setActiveButtonIndex(index);
      setObjectiveFlag(series?.id);
    }
  };


  const { data: objectiveResponse } = useGetAllObjective(
    "Objective",
    true,
    true,
    false
  );
  console.log("objectves", objectiveResponse);
  useEffect(() => {

    if (objectiveResponse && objectiveResponse?.length > 0) {
      setObjectives(objectiveResponse);
    }
  }, [objectiveResponse]);

  const fetchTests = async (page, objective) => {
    setLoading(true);
    console.log(contentCuratorIds, 'KKK1')
    // if (contentCuratorIds.length <= 0) {
    await fetch();
    // }
    try {
      const response = await axios.get(
        `${configUrl.AssessmentServer}/api/Courses`,
        {
          params: {
            pageNumber: page,
            pageSize: pageSize,
            contentFilter: 0,
            contentCuratorIds: null,
            objective: objective || null,
            isMedineerLogin: true
          },
        }
      );
      const fetchedTests = response?.data?.$values || [];
      const totalItems = (response?.data?.$values && response?.data?.$values?.length > 0 && response?.data?.$values[0]?.totalCount) || 0;
      setTestSeries(fetchedTests);
      setTotalPages(Math.ceil(totalItems / pageSize));
      console.log("toatalcount", Math.ceil(totalItems / pageSize), totalItems)
    } catch (error) {
      console.error("Error fetching test series:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTestSeries([]); // Clear previous series
    fetchTests(1, objectiveFlag);
    if (pageNumber !== 1) {
      setPageNumber(1);
    }
  }, [objectiveFlag]);


  useEffect(() => {
    fetchTests(pageNumber);
  }, [pageNumber]);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const scrollRef = useRef(null);

  const handleLearnMore = (series) => {
    navigate('/medineer/test-series-details', { state: { series } });
  };
  const onEnroll = (series) => {
    if (series?.assingnedStudentsCount && series?.testSeriesStudentsCount && series?.testSeriesStudentsCount > 0 && series?.assingnedStudentsCount > series?.testSeriesStudentsCount) {
      message.error("Please contact the admin.");
    } else {
      navigate('/medineer/SignUp', { state: { series } });
    }
  }
  // const scroll = (direction) => {

  //   if (scrollRef.current) {
  //     const newIndex = activeIndex + (direction === 'left' ? -1 : 1);
  //     if (newIndex >= 1 && newIndex <= testSeriesDataDetails.length) {
  //       scrollRef.current.scrollLeft += direction === 'left' ? -400 : scrollAmount;
  //       setActiveIndex(newIndex); //chnge active index on arrow click
  //     }
  //   }
  // };

  const scroll = (direction) => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      const cardWidth = container.firstChild.offsetWidth; // Get card width dynamically
      const scrollAmount = cardWidth * 3; // Calculate scroll for 3 cards
      const maxScrollLeft = container.scrollWidth - container.clientWidth; // Max scrollable distance

      // Determine new scroll position
      const newScrollLeft = direction === 'left'
        ? Math.max(container.scrollLeft - scrollAmount, 0) // Ensure it doesn't scroll past the start
        : Math.min(container.scrollLeft + scrollAmount, maxScrollLeft); // Ensure it doesn't scroll past the end

      // Scroll the container
      container.scrollTo({ left: newScrollLeft, behavior: 'smooth' });

      // Update the active index
      const newIndex = activeIndex + (direction === 'left' ? -3 : 3); // Update by 3 cards
      const maxIndex = Math.ceil(testSeriesDataDetails.length / 3); // Max number of scrollable sections
      setActiveIndex(Math.max(1, Math.min(newIndex, maxIndex))); // Keep index within bounds
    }
  };
  const handleDotClick = (index) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = index * 400; // Adjust the scroll position through on crd width
      setActiveIndex(index + 1);
    }
  };
  // const handleThemeChange = (color) => setThemeColor(color);
  const gradientBackgrounds = {
    // will add it later if req.
    // purple: 'linear-gradient(135deg, #5C4892, #DD2466)',  // Purple gradient
    // green: 'linear-gradient(135deg, #4CAF50, #FF9800)',   // Green gradient
    // blue: 'linear-gradient(135deg, #2196F3, #FFC107)',    // Blue gradient
    // teal: 'linear-gradient(135deg, #029c91, #272740)',    // Teal gradient
    deamblue: 'linear-gradient(176deg, rgb(18, 47, 117) 20%, rgb(41, 39, 147) 40%,rgb(18, 47, 117))',   //'linear-gradient(170deg, rgb(41, 39, 147) 60%, rgb(18, 53, 151) 90%)' // 'linear-gradient(50deg,rgb(18, 53, 151) 0%,rgb(151, 171, 255)  90%)'  //'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',   //#2196F3, #FFC107)', // Dreamblue gradient
    vividorange: 'radial-gradient(circle, #DEC08E, #EDEBE8, #E8D7BB )',
  };
  const contentStyle = {
    height: '460px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  return (
    <>
      <Header />
      <Box className={styles.defaultBackground}>
        {/* First */}
        <Box>
          {/* carousel */}
          <Box
            position="absolute"
            top={0}
            // left={0}
            width="100%"
            // height="50%"
            // zIndex={0}
            overflow="hidden"
          >
            <Carousel autoplay effect="fade">
              {/* {carouselImages.map((item) => ( */}
              <div style={{ position: "relative" }}>
                <div
                  className={styles.Rectangle1}
                  style={{
                    backgroundImage: `url(${'header1.jpg'})`,
                    backgroundSize: "cover",
                    // backgroundPosition: "center",
                    // height: "400px",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // color: "#fff",
                    // textShadow: "0px 0px 5px rgba(0, 0, 0, 0.7)",
                  }}
                >
                  {/* <div style={{ textAlign: "left" }}>
                    <h1 style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      {item.title}
                    </h1>
                    <p style={{ fontSize: "1.2rem" }}>{item.description}</p>
                  </div> */}
                </div>
              </div>
              {/* // ))} */}
            </Carousel>
            {/* <Slider {...settings}>
            {carouselImages.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Slide ${index + 1}`} style={{ width: '100%', borderRadius: '20px' }} />
              </div>
            ))}
          </Slider> */}
          </Box>
          <Grid container spacing={2} justifyContent={'center'}
            sx={{
              position: "relative",
              zIndex: 1,
              marginTop: '10%'
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",
              // height: "100%"
            }}
          >
            <Grid item xs={10} sm={11} sx={{ textAlign: 'start' }}>
              <span className={styles.UnlockYourPotential}>
                Unlock Your Potential
              </span>
              <br />
              <span className={styles.subHeading}>
                Expert-Led Courses to Transform Your Future. Discover a world of knowledge.<br />
                Start your journey today. Empower your future with our courses to choose from.
              </span><br />
              <Button
                className={styles.Rectangle3}
              >
                Explore More
              </Button>

            </Grid>

          </Grid>
          <Box
            // bgcolor={theme.palette.background.main}
            // color={theme.palette.primary.main}
            // padding={2}
            // borderRadius={1}
            // marginBottom={2}
            // position="relative"
            marginTop={'45px'}
          // className={styles.Rectangle4}
          >

            <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '20px' }}>
              <Grid item xs={11} sm={11}>
                <Box className={styles.ScoreRectangle}>
                  <div>
                    {/* add svg icon here */}
                    <img
                      src="/icon1.png"
                      alt="icon1"
                    // style={{ width: '24px', height: '24px', marginBottom: '8px' }}
                    />
                    <span className={styles.scoreCardNumbers}>
                      {`${testSeriesCount?.StudentUsersCount || "..."} +`}
                    </span>
                    <br />
                    <span className={styles.scoreCardLabel}>
                      {'Students'}
                    </span>
                  </div>
                  <Divider orientation="vertical" flexItem style={{ backgroundColor: "black" }} />
                  <div>
                    <img
                      src="/icon2.png"
                      alt="icon2"
                    // style={{ width: '24px', height: '24px', marginBottom: '8px' }}
                    />
                    {/* <Typography variant="h5" align="center" style={{ color: 'black', fontWeight: '700', fontSize: '31px' }}> */}
                    <span className={styles.scoreCardNumbers}>
                      {`${testSeriesCount?.courseCount || "..."} +`}
                    </span>
                    {/* </Typography> */}
                    <br />
                    {/* <Typography align="center" style={{ color: 'black', fontSize: '16px' }}> */}
                    <span className={styles.scoreCardLabel}>
                      {'Test Series'}
                    </span>
                    {/* </Typography> */}
                  </div>
                  <Divider orientation="vertical" flexItem style={{ backgroundColor: "black" }} />
                  <div>
                    <img
                      src="/icon3.png"
                      alt="icon1"
                    // style={{ width: '24px', height: '24px', marginBottom: '8px' }}
                    />
                    <span className={styles.scoreCardNumbers}>
                      {`${testCount || "..."} +`}
                    </span>
                    <br />
                    <span className={styles.scoreCardLabel}>
                      {'Tests'}
                    </span>
                  </div>
                  <Divider orientation="vertical" flexItem style={{ backgroundColor: "black" }} />
                  <div>
                    <img
                      src="/icon4.png"
                      alt="icon1"
                    // style={{ width: '24px', height: '24px', marginBottom: '8px' }}
                    />
                    <span className={styles.scoreCardNumbers}>
                      {`${testSeriesCount?.questionCount || "..."} +`}
                    </span>
                    <br />
                    <span className={styles.scoreCardLabel}>
                      {'Questions'}
                    </span>
                  </div>
                </Box>
              </Grid>
            </Grid>

            {/* <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '20px' }}>
            <Grid item xs={10} sm={10}>
              <Grid container spacing={10} justifyContent="center" style={{ marginBottom: '20px' }}>
                {[
                  {
                    label: 'Students',
                    value: `${testSeriesCount?.StudentUsersCount || "..."} +`,
                    color: theme.palette.scoreCardBackground1.main,
                    textColor: theme.palette.scoreCardText1.main,
                    icon: <SchoolOutlined style={{ color: theme.palette.scoreCardText1.main, fontSize: '50px', float: 'left', position: 'absolute', margin: '0px 5px 0px 14px' }} />
                  },
                  {
                    label: 'Test Series',
                    value: `${testSeriesCount?.courseCount || "..."} +`,
                    color: theme.palette.scoreCardBackground2.main,
                    textColor: theme.palette.scoreCardText2.main,
                    icon: <ListAltOutlined style={{ color: theme.palette.scoreCardText2.main, fontSize: '50px', float: 'left', position: 'absolute', margin: '0px 5px 0px 14px' }} />
                  },
                  {
                    label: 'Tests',
                    value: `${testCount || "..."} +`,
                    color: theme.palette.scoreCardBackground3.main,
                    textColor: theme.palette.scoreCardText3.main,
                    icon: <ArticleOutlined style={{ color: theme.palette.scoreCardText3.main, fontSize: '50px', float: 'left', position: 'absolute', margin: '0px 5px 0px 14px' }} />
                  },
                  {
                    label: 'Questions',
                    value: `${testSeriesCount?.questionCount || "..."} +`,
                    color: theme.palette.scoreCardBackground4.main,
                    textColor: theme.palette.scoreCardText4.main,
                    icon: <HelpOutline style={{ color: theme.palette.scoreCardText4.main, fontSize: '50px', float: 'left', position: 'absolute', margin: '0px 5px 0px 14px' }} />
                  }
                ].map((item, index) => (
                  <Grid item xs={6} sm={3} key={index}>
                    <Card style={{
                      borderRadius: '7px',
                      border: `solid 2px ${theme.palette.primary.main}`,
                      boxShadow: `0 4px 10px ${theme.palette.primary.main}25`,
                      background: `${item.color}`,
                      padding: '20px 0px 20px 0px',
                      textAlign: 'left'
                    }}>
                      <CardContent>
                      {item.icon}
                      <Typography variant="h5" align="center" style={{ color: item.textColor, fontWeight: '500', fontSize: '24px' }}>
                        {item.value}
                      </Typography>
                      <Typography align="center" style={{ color: item.textColor, fontSize: '16px' }}>
                        {item.label}
                      </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid> */}
          </Box>
        </Box>
        {/* second */}
        <Grid container justifyContent="center" className={styles.Rectangle5}>
          <Grid item xs={11} sm={11} sx={{ textAlign: 'left', marginBottom: '75px' }}>
            {/* <Typography variant="h4" gutterBottom style={{ color: theme.palette.sectionHeading.main, fontSize: '34px', textAlign: 'left' }}> */}
            <span className={styles.Discovertheperfectonlineprogram}>
              Discover the perfect online program
            </span>
            {/* </Typography> */}
          </Grid>
          <Grid item xs={11} sm={12} className={styles.Rectangle22}>
            <Grid container justifyContent="center">
              <Grid item xs={11} sm={11}>
                <Box display="flex" justifyContent="start" marginBottom={3}>
                  <IconButton
                    disabled={true}
                    className={styles.filterButton}
                  >
                    <FilterAltIcon className={styles.filterIcon} />
                  </IconButton>
                  <span className={styles.filterLabel}>Filters </span>
                  <Divider orientation="vertical" className={styles.dividerX} flexItem />

                  {objectives && objectives?.length > 0 && objectives.map((series, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      // color="primary"
                      onClick={() => handleNavigation(series, index)}
                      className={styles.filterButtonFonts}
                      style={{
                        background: index == activeButtonIndex ? '#d5caf4' : '#efefef',
                        // marginRight: '10px',
                        // borderRadius: '20px',
                        // fontSize: '14px',
                        // border: `solid 2px ${theme.palette.activeFilterBackground.main}`,
                        color: index == activeButtonIndex ? '#474747' : '#000000',
                      }}
                    >
                      {`${series.valueString}  ${index == activeButtonIndex ? 'X' : '  '}`}
                    </Button>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11} sm={11} md={11}>
              <Box position="relative">
                {/* <IconButton onClick={() => scroll('left')}
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: theme.palette.background.default,
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                    color: theme.palette.primary.main,
                    zIndex: 10, // Ensure this is higher than other components
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.background.default,
                    },
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton> */}

                <Box ref={scrollRef} sx={{ justifyContent: 'center', display: 'flex', overflowX: 'auto', scrollBehavior: 'smooth', '&::-webkit-scrollbar': { display: 'none' } }}>
                  {loading ? (
                    <Box sx={{ flex: '0 0 auto', width: 400, height: 300, m: 1, justifyContent: 'center' }}>
                      <CircularProgress
                        style={{ marginTop: '110px' }}
                      />
                    </Box>
                  )
                    : (
                      testSeries && testSeries?.length > 0 ? (testSeries.map((series, index) => (
                        <Box key={index} sx={{ flex: '0 0 auto', width: 400, m: 1 }}>
                          <TestSeriesCard
                            title={series.text}
                            description={series.description}
                            image={series.image ?? '/Gemini_image_not_available.jpg'}
                            bulletPoints={series?.bulletPoints}
                            price={series?.price || '0'}
                            onLearnMore={() => handleLearnMore(series)}
                            onEnroll={() => onEnroll(series)}
                            testsCount={series?.testCount || 0}
                            testSeriesStudentsCount={series?.assingnedStudentsCount || 0}
                            studentsCount={series?.testSeriesStudentsCount || 0}
                            duration={series.duration}
                            courseId={series.courseId}
                          />
                        </Box>
                      )))
                        : (
                          <Box sx={{ flex: '0 0 auto', width: '700px', height: '300px', m: 1, justifyContent: 'center' }}>
                            <h4 style={{ marginTop: '70px', color: 'white' }}>No tests series found</h4>
                          </Box>)
                    )}
                </Box>

                {/* <IconButton onClick={() => scroll('right')}
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: theme.palette.background.default,
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                    color: theme.palette.primary.main,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.background.default,
                    },
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton> */}
              </Box>

              {/* Test sereies cards Pagination like dots */}
              <Box display="flex" justifyContent="center" mt={2}>
                {totalPages > 1 && (
                  <Pagination
                    count={totalPages}
                    page={pageNumber}
                    onChange={handlePageChange}
                    // color="primary"
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'white', // Default text color
                      },
                      '& .MuiPaginationItem-root.Mui-selected': {
                        backgroundColor: 'white', // Selected background color
                        color: 'black', // Text color for the selected page
                      },
                      '& .MuiPaginationItem-ellipsis': {
                        color: 'white', // Dots color
                      },
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* Third */}
        <div className={styles.Group} style={{
          backgroundImage: `url(${'/banner2.png'})`,
          backgroundSize: "cover",
        }} >
          <span class={styles.Whiletheresnosecretformulaforgettingintogreatcollegeswecansupportyouinbuildingconf}>
            While there’s no secret formula for <br /> getting into great colleges, we can <br /> support you in building confidence.<br /> Learning is vital, but so are making<br />
            <span className={styles.textstyle1}>Timely Decisions</span>,&nbsp;
            <span className={styles.textstyle1}>Analyzing Progress</span>,&nbsp; <br /> and
            <span className={styles.textstyle1}> striving for </span>
            <span className={styles.textstyle1}>Perfection</span>.
          </span>
        </div>
        {/* <Grid container spacing={2} justifyContent="center" className={styles.Group} sx={{
          backgroundImage: `url(${'/banner2.png'})`,
          backgroundSize: "cover",
        }} >
          <Grid item xs={6} sm={5}>
            <Typography sx={{
              color: theme.palette.paragraphText.main,
              fontFamily: 'Fira Sans, sans-serif',
              fontSize: '17px',
              textAlign: 'left'
            }}>
              Though there is no secret souse about the journey to reach at great colleges, but we can certainly become supporting hand for you to build confidence.<br />
              Learning/Understanding is indeed important part of this beautiful journey but being perfect, taking timely decisions, regularly analyse preparation levels is equally important.
            </Typography>
          </Grid>
          <Grid item xs={4} sm={5}>
            <img src={'/background.jpg'} alt={`/background.jpg`} style={{ width: '100%', height: '90%' }} />
          </Grid>
        </Grid> */}
        {/* Forth */}
        <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
          <Grid item xs={11} sm={11}>
            <Typography className={styles.studentFeedbackHeadline}>
              Discover What Our Students Have To Say
            </Typography>
          </Grid>
        </Grid>
        {/* Fifth */}
        <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
          <Grid item xs={11} sm={6} sx={{ justifyItems: 'center' }}>
            <FeedbackCard
              studentName="John Doe"
              feedbackText="Enrolling in this online course was the best decision I made for my college prep journey. The lessons were clear, engaging, and full of practical tips. It helped me boost my confidence, stay organized, and achieve my dream of getting into my top-choice college. Highly recommended"
              rating={5}
              studentImage="https://via.placeholder.com/150"
            />
          </Grid>
          <Grid item xs={10} sm={6} sx={{ justifyItems: 'center' }}>
            <FeedbackCard
              studentName="Suraj Doe"
              feedbackText="This online course was a game-changer for me! The content was easy to follow, and the guidance helped me stay on track with my preparation. It boosted my confidence and made a huge difference in achieving my goals. Highly recommend it!"
              rating={5}
              studentImage="https://via.placeholder.com/150"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Home;
