import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { configUrl } from "../config";
import { LoginContext } from "../contexts/LoginContext";

export default function useGetAssignedCoursesById(isSubscribed = true) {
  const { UserId, testSeriesStatus } = useContext(LoginContext);
  console.log(UserId, testSeriesStatus, "seriesKeys")
  return useQuery("courses", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Courses/assignedCoursesById/${UserId}/${testSeriesStatus}?isSubscribed=${isSubscribed}`
    );
    return response.data["$values"];
  },
    {
      staleTime: 1000 * 60 * 5,
    });
}
