import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import { useTheme } from "@mui/material";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Components/CustomButtons/Button';
import { LoginContext } from "../contexts/LoginContext";
import styles from '../styles/DetailingTable.module.css';
function createData(topicName, yourScore, avgScore, topScore,) {
  return {
    topicName,
    yourScore,
    avgScore,
    topScore,
  };
}

const config = (open) => ({
  from: { height: 0, opacity: 0, transform: "translate3d(20px,0,0)" },
  to: {
    height: open ? "auto" : 0,
    opacity: open ? 1 : 0,
    transform: open ? "translate3d(0px,0,0)" : "translate3d(20px,0,0)",
  },
});

export default function AnalyticsTable({ analyticsData }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { packageId } = React.useContext(LoginContext);
  const [open, setOpen] = React.useState(false);


  const renderTree = (nodes) => {
    console.log("renderTree1", nodes)
    console.log("renderTree/Name", nodes.Name)
    // const FormatDuration = (durationInMillis) => {
    //   // Check if durationInMillis is a valid number
    //   if (typeof durationInMillis !== 'number') {
    //     return <div><small>NA</small></div>;
    //   }
    //   // Convert milliseconds to minutes
    //   const durationInMinutes = durationInMillis / (1000 * 60);

    //   // Use fixed-point notation with 2 decimal places
    //   const formattedDuration = durationInMinutes.toFixed(2);

    //   return formattedDuration
    // }
    function FormatDuration(durationInMillis) {
      // Convert milliseconds to seconds
      let seconds = Math.floor(durationInMillis / 1000);

      // Calculate minutes and remaining seconds
      let minutes = Math.floor(seconds / 60);
      seconds %= 60;

      // Format the time
      let formattedTime = `${minutes}:${seconds}`;

      return formattedTime;
    }
    return (
      <TreeItem
        key={nodes.Guid}
        nodeId={nodes.Guid}
        label={
          <div>
            <div xs={6} sm={6} md={4}>
              <p style={{ float: 'left', marginTop: '19px', fontSize: '14px' }}>

                {nodes.Name}{" "}

              </p>
            </div>
            <div xs={6} sm={6} md={8} style={{ float: 'right' }}>
              {
                packageId == 1 || packageId == 2 || packageId == 3 ?
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="behance"
                    style={{ float: 'left', marginInline: '16px', backgroundColor: '#4C8055', height: '44px', width: '47px', fontSize: '14px' }}
                  >
                    {
                      nodes.Label == 'DURATION' ?
                        FormatDuration(nodes.Your) : nodes.Your
                    }
                    {
                      nodes.Label == 'ATTEMPTRATE' ? '%' : nodes.Label == 'ACCURACYRATE' ? '%' : ''
                    }
                  </Button> : null
              }
              {
                packageId == 3 ?
                  <Button
                    size="sm"
                    justIcon
                    round
                    style={{ float: 'initial', marginInline: '16px', backgroundColor: '#9da39d', height: '44px', width: '47px', fontSize: '14px' }}
                  >
                    {
                      nodes.Label == 'DURATION' ?
                        FormatDuration(nodes.Average) : nodes.Average
                    }
                    {
                      nodes.Label == 'ATTEMPTRATE' ? '%' : nodes.Label == 'ACCURACYRATE' ? '%' : ''
                    }
                  </Button> : null
              }
              {
                packageId == 3 ?
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="primary"
                    style={{ float: 'right', marginInline: '16px', backgroundColor: '#34C0D3', height: '44px', width: '47px', fontSize: '14px' }}
                  >
                    {
                      nodes.Label == 'DURATION' ?
                        FormatDuration(nodes.Top) :
                        nodes.Top
                    }
                    {
                      nodes.Label == 'ATTEMPTRATE' ? '%' : nodes.Label == 'ACCURACYRATE' ? '%' : ''
                    }
                  </Button> : null
              }
            </div>
          </div>
        }
      >
        {Array.isArray(nodes.SubObjectList) && nodes.SubObjectList.length > 0
          ? nodes.SubObjectList.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  return (
    <div>

      <div>
        {/* <div xs={6} sm={6} md={4}>
          <p className={styles.tableHeading}>Topics</p>
        </div> */}
        <div xs={6} sm={6} md={8} style={{ float: 'right' }}>
          {
            packageId == 1 || packageId == 2 || packageId == 3 ?
              <p className={styles.tableHeading} style={{ color: theme.palette.heading.main }} >{t("you")}   </p> : null
          }
          {
            packageId == 3 ?
              <p className={styles.tableHeading} style={{ color: theme.palette.heading.main }} >{t("avg")}</p> : null
          }
          {
            packageId == 3 ?
              <p className={styles.tableHeading} style={{ color: theme.palette.heading.main }} >{t("top")}    </p> : null
          }
        </div>
      </div>

      <TreeView
        // className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        // defaultExpanded={["root"]}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{
          height: 264,
          flexGrow: 1,
          maxWidth: 1000,
          overflowY: "false",
        }}
        disableSelection={true}
      >

        {
          analyticsData ?

            analyticsData.map((row, i) => (
              renderTree(analyticsData[i])
            )) : ''
        }

      </TreeView>
    </div>
  );
}