/* eslint-disable import/first */
import 'katex/dist/katex.min.css';
import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import Latex from 'react-latex-next';
{/* latex CSS */ }
// material-ui
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Box from "@material-ui/core/Box";

import dashboardStyle from "../../assets/dashboardStyle";
import loginPageStyle from "../../assets/loginPageStyle";
// import Radio from "@material-ui/core/Radio";

import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Divider from "@material-ui/core/Divider";
import radioStyles from "../../assets/customCheckboxRadioSwitch";

// import GridContainer from "components/Grid/GridContainer.js";

// import GridItem from "components/Grid/GridItem.js";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import { CachedOutlined, ImportContactsSharp } from '@material-ui/icons';
import { Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Bookmark from "../../Bookmark/Bookmark";
import Hint from "../../Hint/Hint";
import Paragraph from '../../Paragraph/Paragraph';
import { LoginContext } from '../../contexts/LoginContext';
import { TestContext } from "../../contexts/TestContext";
import questionstyles from '../../styles/QuestionTypes.module.css';
import Button from '../CustomButtons/Button';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Solution from '../Solution/Solution';

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  ...radioStyles,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function MultipleChoiceQuestion({ qNoes, question, questionObj, contentType, updateQuestion, preview }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { test, selectEvent, setSelectEvent } = useContext(TestContext);


  console.log("options", question.options)
  const [selected, setSelected] = React.useState([]);
  const [showSolution, setShowSolution] = React.useState(false);
  const { userPrivileges } = useContext(LoginContext)

  const isPrivilege = (x) => {
    if (userPrivileges?.privileges?.test?.$values?.includes(x)) {
      return true;
    } else {
      return false;
    }
  };
  let choices;
  if (isJSON(question.answer)) {
    // choices = JSON.parse(question.answer)
    choices = question.answer && JSON.parse(question.answer).map((element) => {
      return element?.trim()?.toLowerCase()?.replace(/[(),]/g, '');
    });
    console.log(choices, 'parsed');

  } else {
    let Qanswers = question.answer?.trim()?.toLowerCase()?.replace(/[(),]/g, '')
    choices = Qanswers.split(",");
  }

  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleCheck = (event) => {
    let currentlySelected = [...selected];
    setSelectEvent(selectEvent == true ? false : true)
    if (event.target.checked === true) {
      currentlySelected.push(event.target.name);
    } else {
      currentlySelected = currentlySelected.filter(
        (x) => x !== event.target.name
      );
    }
    setSelected([...currentlySelected]);

    question.response = currentlySelected;

    question.isCorrect = false;
    console.log(currentlySelected, 'mcqSelected')
    console.log(choices, 'Choices123')
    function isempty(x) {
      if (x !== "")
        return true;
    }
    let choicesLength = choices.filter(isempty)
    console.log(choicesLength, 'choicesLength1234')
    if (currentlySelected.length === choicesLength.length) {
      question.isCorrect = true;
      for (let s of currentlySelected) {
        if (!choicesLength.includes(s?.trim()?.toLowerCase()?.replace(/[(),]/g, ''))) {
          question.isCorrect = false;
          break;
        }
      }
      console.log("true");
    }
  };
  const onClearHandle = () => {
    setSelected([]);
    question.response = null;
    setSelectEvent(selectEvent == true ? false : true)
  }

  React.useEffect(() => {
    setSelected([]);
    if (question.response) {
      setSelected(question.response);
    }
  }, [question]);

  // let questionsResponse;
  // let responseParse;
  // console.log(question?.response, "isType0", typeof (question?.response));
  // if (isJSON(question?.response)) {
  //   console.log(question?.response, "isType1", typeof (question?.response));
  //   responseParse = question?.response && JSON.parse(question?.response)
  // } else {
  //   console.log(question?.response, "isType2", typeof (question?.response));
  //   responseParse = question?.response
  // }
  // console.log(question?.response, "isType3", typeof (question?.response));
  // questionsResponse = responseParse && responseParse?.map((element) => {
  //   return element?.trim()?.toLowerCase()?.replace(/[(),]/g, '');
  // });

  let questionsResponse;
  let responseParse;

  console.log(question?.response, "isType0", typeof (question?.response));

  if (isJSON(question?.response)) {
    console.log(question?.response, "isType1", typeof (question?.response));
    responseParse = JSON.parse(question?.response);
  } else {
    console.log(question?.response, "isType2", typeof (question?.response));

    if (Array.isArray(question?.response)) {
      responseParse = question?.response;
    } else if (typeof question?.response === 'object' && question?.response !== null) {
      responseParse = [question?.response]; // Conv. object to array
    } else {
      responseParse = [question?.response];
    }
  }

  if (Array.isArray(responseParse)) {
    questionsResponse = responseParse.map((element) => {
      return element?.trim()?.toLowerCase()?.replace(/[(),]/g, '');
    });
  } else {
    console.error("responseParse is not an array:", responseParse);
    questionsResponse = [String(responseParse)];
  }

  console.log(questionsResponse, 'questionsResponse')

  const classes = useStyles();
  var options = question.options["$values"] != undefined ? question.options["$values"] : question.options;

  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" p={2}>
        <Box p={2} sx={{ width: "100%" }}>
          <GridContainer alignItems="center" spacing={1}>
            <GridItem xs={5} sm={5} md={5}>
              <div style={{ float: 'left', marginRight: '5px', fontSize: '21px' }}> {t("question")} {qNoes}. </div>
            </GridItem>
            <GridItem xs={7} sm={7} md={7}>
              <Box p={1} style={{ float: 'right' }}>
                {(questionObj[0]?.ParagraphImage != null || questionObj[0]?.ParagraphText != null) ? (
                  <Paragraph question={question} messageImage={questionObj[0]?.ParagraphImage} messageText={questionObj[0]?.ParagraphText} contentType={contentType} questionId={question.id} />
                ) : null
                }
                {
                  preview != true ?
                    <Tooltip title={t('clearSol')} classes={{ tooltip: classes.tooltip }} placement="bottom">
                      < CachedOutlined onClick={onClearHandle} style={{ color: theme.palette.primary.main, fontSize: 30 }} />
                    </Tooltip>
                    : null
                }
                {
                  preview == true
                    ? <h6 style={{ float: 'right', color: theme.palette.heading.main }}> {` ${t('Section')} : ${question?.poolText}`}</h6> : null
                }
                {isPrivilege("Bookmark") == true && preview != true && test && test.showBookmark == 2 ? (
                  <Bookmark question={question} message={questionObj[0]?.Hint} questionId={question.id} />
                ) : null}
                {isPrivilege("ShowHint") == true && preview != true && test && test.showHint == 2 ? (
                  <Hint question={question} message={questionObj[0]?.Hint} contentType={contentType} questionId={question.id} />
                ) :
                  null
                }
              </Box>
            </GridItem>
          </GridContainer>
          {/* <Box p={1} style={{ float: 'right' }}>
            </Box> */}

          <Box display="flex" p={1} style={{ padding: 0 }}>
            <Box p={1} flexGrow={1} style={{ padding: 0, float: 'left', fontSize: '22px' }}>
              {
                question && questionObj[0]?.Text && questionObj[0]?.Text != null && questionObj[0]?.Text?.length > 0 ? (contentType == 1 ?
                  <div style={{ color: 'black', fontSize: '20px', marginBottom: '10px' }}>{questionObj[0]?.Text}</div>
                  : contentType == 3 ?
                    <Latex style={{ color: 'black' }}>{questionObj[0]?.Text}</Latex>
                    :
                    contentType == 4 ?
                      <div style={{ color: 'black', fontSize: '20px' }}>{questionObj[0]?.Text && ReactHtmlParser(questionObj[0]?.Text)}</div>
                      : <div style={{ color: 'black', fontSize: '20px', marginBottom: '10px' }}>{questionObj[0]?.Text}</div>
                )
                  : null
              }
            </Box>
          </Box>

          <Box p={1}>
            {questionObj[0]?.Image && <img src={questionObj[0]?.Image} alt="..." className={questionstyles.Questionimage} />}
          </Box>
          <GridContainer alignItems="center" spacing={1}>
            {options
              ?.filter((x) => x != null && x != "")
              .map((x, i) => (
                <GridItem xs={11} sm={11} md={6}>
                  <GridContainer spacing={1} key={i}>
                    <GridItem xs={8} sm={8} md={10}>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={handleCheck}
                              checked={
                                questionsResponse &&
                                  questionsResponse.indexOf(x?.trim()?.toLowerCase()?.replace(/[(),]/g, '')) !== -1
                                  ? true
                                  : false
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon}
                                  style={{ color: theme.palette.primary.main }}
                                />
                              }
                              icon={<Check className={classes.uncheckedIcon}
                                style={{ color: theme.palette.primary.main }}
                              />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                              name={x}
                              disabled={question.isSubmitted}
                            />
                          }
                          classes={{ label: classes.label }}
                          label={
                            contentType == 1 ?
                              x : contentType == 3 ?
                                <Latex style={{ color: 'black' }}>{x}</Latex> :
                                contentType == 4 ?
                                  ReactHtmlParser(x) :
                                  x
                          }
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} style={{ alignSelf: 'center' }}>
                      {(question.isSubmitted == true || preview == true) ? (
                        <GridItem xs={11} sm={11} md={6}>
                          {choices && choices.indexOf(x?.trim()?.toLowerCase()?.replace(/[(),]/g, '')) !== -1 ? (
                            <CheckIcon style={{ color: "green" }} />
                          ) : (
                            question.isCorrect == false && <CloseIcon style={{ color: "red" }} />
                          )}
                        </GridItem>
                      ) : null}

                    </GridItem>
                  </GridContainer>
                  <GridItem xs={11} sm={11} md={11}>
                    <Divider />
                  </GridItem>
                </GridItem>
              ))}
          </GridContainer>
          <div style={{ marginBottom: '4%' }}>
            {
              preview == true ?
                <Button
                  // color="primary"
                  style={{
                    backgroundColor: theme.palette.primaryButtonBackground.main,
                  }}
                  onClick={() => setShowSolution(showSolution ? false : true)}>
                  <ImportContactsSharp style={{ marginRight: '5px' }} />
                  {
                    showSolution ? t('hideSolution') : t('showSolution')
                  }
                </Button>
                : null
            }
            {
              showSolution ?
                <Solution
                  key={qNoes}
                  question={question}
                  questionObj={questionObj}
                  contentType={question.contentType}
                  updateQuestion={() => { }}
                  preview={preview}
                />
                : null
            }
          </div>
        </Box>
      </Box>
    </div >
  );
}
