import axios from "axios";
import { configUrl } from "../config";

// TO DO - will manipulate this services into one demographic service 
export async function getState(key, isActive) {
  console.log(key, "key");
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/DemoghraphicInformation/${key}/${false}/${isActive}/${true}`
    )
    .then((respose) => {
      console.log("responce", respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export async function getCity(key, isActive) {
  console.log(key);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/DemoghraphicInformation/${key}/${false}/${isActive}/${true}`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export async function getLanguage(key, isActive) {
  console.log(key);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/DemoghraphicInformation/${key}/${false}/${isActive}/${true}`
      // `${configUrl.AssessmentServer}/api/DemoghraphicInformation/getAllDemographicInformaionKeys`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export async function getInterest(providerId) {
  console.log(providerId);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/Interest/getInterestByProviderId/${providerId}`
    )
    .then((respose) => {
      console.log(respose.data, "Interestby provider");
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export async function getEducationBoard(key, isActive) {
  console.log(key);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/DemoghraphicInformation/${key}/${false}/${isActive}/${true}`
      // `${configUrl.AssessmentServer}/api/DemoghraphicInformation/getAllDemographicInformaionKeys`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export async function getClasses(key, isActive) {
  console.log(key);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/DemoghraphicInformation/${key}/${false}/${isActive}/${true}`
      // `${configUrl.AssessmentServer}/api/DemoghraphicInformation/getAllDemographicInformaionKeys`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export async function getProfession(key, isActive) {
  console.log(key);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/DemoghraphicInformation/${key}/${false}/${isActive}/${true}`
      // `${configUrl.AssessmentServer}/api/DemoghraphicInformation/getAllDemographicInformaionKeys`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
