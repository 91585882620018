import { useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoginContext } from "../../contexts/LoginContext";
import Styles from '../../styles/TimerView.module.css';

function SecondsToTimeFormat({ seconds }) {
    const theme = useTheme();
    const secondsToHms = (seconds) => {
        // const hours = Math.floor(seconds / 3600);
        // const minutes = Math.floor((seconds % 3600) / 60);

        const remainingHours = String(Math.floor(seconds / 3600)).padStart(2, '0')
        const remainingMins = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0')
        const remainingSeconds = String(Math.trunc(seconds - (Math.floor(seconds / 60)) * 60)).padStart(2, '0')
        return <span className={Styles.timerControl} style={{
            color: theme.palette.primaryButtonBackground.main
        }}>{`${remainingHours} : ${remainingMins} : ${remainingSeconds}`}</span>
    };

    const formattedTime = secondsToHms(seconds);

    return (
        <div>
            <p>{formattedTime}</p>
        </div>
    );
}

export default function TimerView(props) {
    const { t } = useTranslation();
    const { setAlertFlag, UserId } = useContext(LoginContext);
    const [second, setSecond] = useState(props.duration * 60)

    const [exitTime, setExitTime] = useState(null)
    const [second2, setSecond2] = useState(props.duration * 60)
    //TODO- Pass this timoutout to parent page
    useEffect(() => {
        let resumedTime = JSON.parse(localStorage.getItem(`${UserId}remainingTime`))
        let resumeTestId = localStorage.getItem(`${UserId}TestId`)
        console.log(resumedTime * 1000, typeof (resumedTime), 'resumedTime');
        if (resumeTestId != null && resumedTime != null && props.TestId == resumeTestId) {
            setSecond2(resumedTime)
            setExitTime(new Date().getTime() + resumedTime * 1000)
        }
    }, [])
    useEffect(() => {
        if (props.testTFlag == true) {
            if (!exitTime)
                setExitTime(new Date().getTime() + props.duration * 60 * 1000)
            const intervalId = setInterval(() => {
                if (props.duration !== null && second2 <= 0) {
                    props.setTimeout();
                    // setInterval()
                } else {
                    setSecond(second - 1)
                    if (exitTime) {
                        if (exitTime > new Date().getTime()) {
                            setSecond2((exitTime - new Date().getTime()) / 1000)
                            localStorage.setItem(`${UserId}remainingTime`, JSON.stringify(second2));
                        } else {
                            setSecond2(0)
                        }
                    }
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [props.testTFlag, second])

    return (<>
        {
            props.showTimer == 2 && props.duration !== null ?
                // <span style={{ float: 'left', marginLeft: '1%', color: 'orange', fontWeight: '700', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '16px', marginTop: '10px' }}><TimerSharp style={{ fontSize: '30px', marginRight: '10px' }} />{`${Math.floor(second / 3600)}:${String(Math.floor(second / 60)).padStart(2, '0')}:${String(second - (Math.floor(second / 60)) * 60).padStart(2, '0')}`} </span>                 //{t('timeLeft')} : 
                // <span className={Styles.timerControl}>{`${Math.floor(second2 / 3600)}:${String(Math.floor(second2 / 60)).padStart(2, '0')}:${String(Math.trunc(second2 - (Math.floor(second2 / 60)) * 60)).padStart(2, '0')}`}{' '} </span>                  //{new Date(exitTime).toString()}  <TimerSharp className={Styles.timerIcon} />
                <SecondsToTimeFormat seconds={second2} />
                : null
        }
    </>
    );
}