import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { configUrl } from "../config";
import applyCouponService from "../services/applyCouponService";

const CheckoutPage = ({ course, onProceedToPayment }) => {
    const [coupon, setCoupon] = useState("");
    const [couponID, setCouponID] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    console.log("coursedataCheck", course);
    const handleApplyCoupon = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const applyCouponData = await applyCouponService.applyCoupon(coupon);

            if (applyCouponData.message === "Coupon code not found") {
                setDiscount(0);
                setError("Invalid coupon code");
                return;
            } else if (applyCouponData?.providerId && applyCouponData?.providerId != configUrl.MediNeerProviderId) {
                setDiscount(0);
                setError("Coupon Expired.");
                return;
            }
            else if (applyCouponData?.isActive === false) {
                setDiscount(0);
                setError("Coupon Expired.");
                return;
            } else if (applyCouponData?.validFrom || applyCouponData?.validTo) {
                console.log("dates", applyCouponData?.validFrom, applyCouponData?.validTo);
                let valid = true;

                const now = new Date();
                now.setHours(0, 0, 0, 0); // Reset current time to midnight

                if (applyCouponData?.validFrom) {
                    const validFromDate = new Date(applyCouponData.validFrom);
                    validFromDate.setHours(0, 0, 0, 0); // Reset validFrom date to midnight
                    console.log("validFromDate", validFromDate, now);

                    if (now < validFromDate) {
                        valid = false; // Current date is before the validFrom date
                        console.log("validFrom check failed");
                    }
                }

                if (applyCouponData?.validTo) {
                    const validToDate = new Date(applyCouponData.validTo);
                    validToDate.setHours(0, 0, 0, 0); // Reset validTo date to midnight
                    console.log("validToDate", validToDate, now);

                    if (now > validToDate) {
                        valid = false; // Current date is after the validTo date
                        console.log("validTo check failed");
                    }
                }

                if (!valid) { // If not valid
                    setDiscount(0);
                    setError("Coupon Expired.");
                    return;
                }
            }


            if (applyCouponData?.resourceId && applyCouponData?.resourceId != course?.courseId) {
                setDiscount(0);
                setError("Invalid coupon code");
                return;
            }
            console.log("data", applyCouponData?.id);
            if (applyCouponData?.discountPercentage) {
                setCouponID(applyCouponData?.id);
                setDiscount(applyCouponData?.discountPercentage);
                setError("");
            }
        } catch (error) {
            console.error("Error occurred", error);
            if (error?.response?.status === 404) {
                setDiscount(0);
                setError("Invalid coupon code");
                return;
            }
            setError("An unexpected error occurred. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    //const finalPrice = course?.price - (course?.price * discount) / 100;
    const finalPrice = course?.price ? course.price - (course.price * discount) / 100 : 0;
    const savings = course?.price ? (course.price * discount) / 100 : 0;
    return (
        <Box
            sx={{
                padding: 3,
                maxWidth: 600,
                margin: "auto",
                backgroundColor: "#f9f9f9", // Light grey background for the whole component
                borderRadius: 2,
                boxShadow: "0 4px 10px rgba(0,0,0,0.1)", // Subtle shadow for better aesthetics
            }}
        >
            <Box sx={{ mb: 2 }}>
                <Typography variant="h4" sx={{ fontSize: "2.1rem", fontWeight: "bold" }}>
                    {course?.text || course?.courseName}
                </Typography>
                {/* <Typography
                    variant="h5"
                    color="text.secondary"
                    sx={{ fontSize: "1.6rem" }}
                >
                    Original Price: ₹{course?.price || 0}
                </Typography> */}
            </Box>
            <Divider sx={{ my: 1 }} />
            {/* <Typography variant="h6" gutterBottom sx={{ fontSize: "1.4rem" }}>
                Apply Coupon
            </Typography> */}
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        size="small"
                        placeholder="Enter Coupon Code"
                        sx={{
                            mb: 1,
                            "& .MuiInputBase-input": { fontSize: "1.5rem" },
                            "& .MuiFormHelperText-root": {
                                fontSize: "1.2rem", // Increased helper text size
                            },
                        }}
                        InputProps={{ style: { fontSize: "1.5rem" } }}
                        InputLabelProps={{ shrink: false }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleApplyCoupon}
                        disabled={isLoading}
                        size="large"
                        sx={{ textTransform: "none", fontSize: "1.3rem" }}
                        style={{ marginBottom: "7px" }}
                    >
                        {isLoading ? <CircularProgress size={24} /> : "Apply"}
                    </Button>
                </Grid>
            </Grid>
            {error && (
                <Alert severity="error" sx={{ mt: 1, fontSize: "1rem" }}>
                    {error}
                </Alert>
            )}
            {discount > 0 && (
                <Alert severity="success" sx={{ mt: 1, fontSize: "1rem" }}>
                    Coupon applied! You saved ₹{savings.toFixed(2)}.
                    <Button
                        variant="text"
                        //color="inherit"
                        size="small"
                        onClick={() => {
                            setCoupon("");
                            setDiscount(0);
                            setCouponID(null);
                        }}
                        sx={{
                            ml: 2,
                            textTransform: "none",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                        }}
                    >
                        Remove
                    </Button>
                </Alert>
            )}
            <Divider sx={{ my: 2 }} />
            <Box
                sx={{
                    mb: 2,
                    p: 2,
                    backgroundColor: "#e0e0e0", // Light grey background for order summary
                    borderRadius: 1,
                }}
            >
                <Typography variant="h6" sx={{ fontSize: "1.6rem", fontWeight: "bold" }}>
                    Order Summary
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: "1.3rem" }}>Total MRP</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography sx={{ fontSize: "1.3rem" }}>₹{course?.price || 0}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: "1.3rem" }}>Coupon Discount:</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography sx={{ fontSize: "1.3rem" }}>
                            - ₹{savings.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                            Amount Payable
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography sx={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                            ₹{finalPrice.toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Button
                variant="contained"
                color="secondary"
                fullWidth
                size="large"
                onClick={() => onProceedToPayment({ couponID, finalPrice })}
                sx={{
                    textTransform: "none",
                    fontSize: "1.4rem",
                    //fontWeight: "bold",
                }}
            >
                Proceed to Payment
            </Button>
        </Box>

    );
};

export default CheckoutPage;
