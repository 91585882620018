
import { useTheme } from '@emotion/react';
import { getMessaging, getToken } from '@firebase/messaging';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert, Box, Button, Container, Grid, IconButton, InputAdornment, Link, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { message, Modal } from 'antd';
import axios from 'axios';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { configUrl } from '../../config';
import { LoginContext } from '../../contexts/LoginContext';
import app from '../../firebase';
import getPrivilegeService from '../../services/getPrivilegeService';
import { getSiblingData, getStudentDetail } from '../../services/parentService';
import useGetMedineerUser from '../../userHooks/useGetMedineerUser';
import useParentLearnerLogin from '../../userHooks/useParentLearnerLogin';
import SwitchProvider from '../SwitchProvider';
import MedineerLogin from './MedineerLogin';

function NewSignIn() {
    const navigate = useNavigate();
    const location = useLocation();
    const { series } = location?.state || {};
    console.log("coursedata", series);
    const theme = useTheme();
    const [loginUsername, setLoginUsername] = React.useState("");
    const [parentUsername, setParentUsername] = React.useState("");
    const [isLoginAsParent, setLoginAsParent] = React.useState(false);
    const [loginPassword, setLoginPassword] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [severty, setSeverty] = React.useState("success");
    const [googleAuth, setGoogleAuth] = React.useState(false);
    const [showModel, setShowModel] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);

    const { t } = useTranslation();

    const {
        setUserName,
        UserName,
        setProviderKey,
        setProviderName,
        setEmailC,
        setProviderId,
        userPrivileges,
        setPackageId,
        setUserPrivileges,
        setUserId,
        setUserDetail,
        userDetail,
        setUserType,
        siblingDetail,
        setSiblingDetail,
        setEducationDetails,
        setIsDrawerNavigation,
        setPageKey, setIsDefaultSplash,
        setIsParentLogin
    } = useContext(LoginContext);

    const {
        data: loginDetails,
        isLoading,
        isSuccess,
        mutate: getUserDetails,
        error: userFetchError,
    } = useGetMedineerUser();

    const {
        data: parentLoginDetails,
        isLoading: isLoadingParentLogin,
        isSuccess: isSuccessParentLogin,
        mutate: getParentUserDetails,
        error: parentFetchError,
    } = useParentLearnerLogin();

    const handleChangeEmail = (e) => {
        console.log("providerIds123", e.target.value);
        setLoginUsername(e.target.value);

    };

    const handleParentUserName = (e) => {
        console.log(e.target.value, "setParentUsername");
        setParentUsername(e.target.value);
    };

    const handleChangePassword = (e) => {
        setLoginPassword(e.target.value);

    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
    const handleShowCheckout = () => {
        setShowCheckout(true);
    };

    // Handler for closing the modal
    const handleHideCheckout = () => {
        setShowCheckout(false);
        navigate("/medineer");
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let loginInfo = {};
        console.log("Submitting login:", loginUsername, loginPassword);

        loginInfo.username = loginUsername;
        loginInfo.providerId = configUrl.MediNeerProviderId;
        loginInfo.isMedinerLogin = series == null ? false : true;
        loginInfo.courseId = series == null ? 0 : series?.courseId;
        try {
            await getUserDetails(loginInfo); // Wait for the API call to complete.
            console.log("Login successful");
        } catch (error) {
            console.error("Error during login:", error);
        }
    };

    const onParentLoginSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let parentLoginInfo = {};
        parentLoginInfo.parentEmail = parentUsername;
        parentLoginInfo.studentUserName = loginUsername;
        parentLoginInfo.providerId = configUrl.MediNeerProviderId;
        console.log("providerIds", parentLoginInfo);
        getParentUserDetails(parentLoginInfo);
    };

    const getData = async () => {
        if (loginDetails?.userDetails) {
            // const getEduDta = await geteducationData(loginDetails?.userDetails.userId);
            // setEducationDetails(getEduDta);
            const getSiblingDta = await getSiblingData(
                loginDetails?.userDetails.userId
            );
            setSiblingDetail(getSiblingDta.$values[0]);
            const StudentDetail = await getStudentDetail(
                loginDetails?.userDetails.userId
            );
            setUserDetail(StudentDetail?.studetnInfo);
            setEducationDetails(StudentDetail?.educationInfo);

            console.log(StudentDetail, "getStudentDetail123");
        }
    };

    const getDataParent = async () => {
        if (parentLoginDetails?.userDetails) {
            const StudentDetail = await getStudentDetail(
                parentLoginDetails?.userDetails.userId
            );
            setUserDetail(StudentDetail?.studetnInfo);
            setEducationDetails(StudentDetail?.educationInfo);
            console.log(StudentDetail, "getStudentDetail123");
        }
    };
    const messaging = getMessaging(app);

    const activateMessages = async () => {
        console.log("messaging", messaging);
        const token = await getToken(messaging, {
            vapidKey:
                "BFLHmJUchm5llz8UsotJ8ztrq30QWeATAIwEb-Oxl2lEjztAG1qYRWXxkccoQJfLGY2vVbYj0NuQHdUteFT0570",
        }).catch((error) => console.log("error generatin token"));

        if (token) {
            console.log("After Login token", token);
            const payload = {
                userId: loginDetails?.userDetails.userId,
                fcmToken: token,
            };
            let isParentLogin = false;
            let response = await axios.post(
                `${configUrl.AssessmentServer}/api/Counselling/setFCMKey/${isParentLogin}`,
                payload
            );

            return response.data;
        } else if (!token) {
            console.log("no token");
        }
    };

    const handleValidityExpired = () => {
        setOpen(true);
        setSeverty("error");
        setAlertMessage("User account is not active.");
        console.log("email reset successfully.");
        setTimeout(() => {
            setOpen(false);
        }, 2500);
    };
    const showProviderList = async (providerlist) => {
        console.log(providerlist, "providerlists");
        setShowModel(
            <Modal
                title={t("select")}
                open={true}
                onOk={hideEvaluationModel}
                onCancel={hideEvaluationModel}
                footer={null}
                centered
            >
                <SwitchProvider
                    providerlist={providerlist}
                    onClose={hideEvaluationModel}
                    onValidityExpired={handleValidityExpired}
                    redirectToMain={false}
                />
            </Modal>
        );
    };

    const hideEvaluationModel = (event, reason) => {
        console.log("reason", reason);
        if (reason === "backdropClick") {
            return;
        }
        setShowModel(null);
    };
    const setPrivileges = async () => {
        const privilegesDetails = await getPrivilegeService.getPrivileges(
            loginDetails?.userDetails.userId
        );
        setUserPrivileges(privilegesDetails);
        setPackageId(privilegesDetails.packageId);
    };
    const convertToLocalTime = (utcDateTime, endOfDay = false) => {
        const date = new Date(utcDateTime);
        const localDate = new Date(
            date.getTime() - date.getTimezoneOffset() * 60 * 1000
        );

        if (endOfDay) {
            localDate.setHours(23, 59, 59, 999);
        } else {
            localDate.setHours(0, 0, 0, 0);
        }
        console.log(localDate, "localDates");
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, "0");
        const day = String(localDate.getDate()).padStart(2, "0");
        const hours = String(localDate.getHours()).padStart(2, "0");
        const minutes = String(localDate.getMinutes()).padStart(2, "0");
        const seconds = String(localDate.getSeconds()).padStart(2, "0");
        const milliseconds = String(localDate.getMilliseconds()).padStart(3, "0");

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    };
    const getCurrentDateTimeFormatted = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
        return `${year}-${month}-${day}T00:00:00.00Z`;
    };

    useEffect(() => {
        if (userFetchError === "Not Found") {
            setLoading(false);
            setOpen(true);
            setSeverty("error");
            setAlertMessage("Please check userId or password");
            console.log("user name not exist.");
        }
        if (isSuccess) {
            if (
                loginDetails?.userDetails &&
                loginDetails?.userDetails.userName.toLowerCase() ==
                loginUsername.toLowerCase() &&
                (loginDetails?.userDetails.passCode == loginPassword ||
                    googleAuth == true)
            ) {
                console.log(loginDetails, "CVer123");
                setUserId(loginDetails?.userDetails.userId);
                setUserName(loginDetails?.userDetails.fullName);
                setEmailC(loginDetails?.userDetails.userName);
                // let privileges = loginDetails?.privilegeObject ? JSON.parse(loginDetails?.privilegeObject) : "";
                // setUserPrivileges(privileges?.Value);

                setPageKey(1);

                if (loginDetails.userDetails.providerLists["$values"].length === 1) {
                    const validFromLocal = convertToLocalTime(
                        loginDetails?.userDetails.validFrom,
                        false
                    );
                    const validToLocal = convertToLocalTime(
                        loginDetails?.userDetails.validTo,
                        false
                    );
                    const now = getCurrentDateTimeFormatted();

                    if (
                        new Date(now) >= new Date(validFromLocal) &&
                        new Date(now) <= new Date(validToLocal)
                    ) {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        setProviderId(loginDetails.userDetails.providerId);
                        axios.defaults.headers.common = {
                            providerId: loginDetails.userDetails.providerId,
                        };
                        setProviderName(
                            loginDetails.userDetails.providerLists["$values"][0].providerName
                        );
                        setPrivileges();
                        getData();
                        sessionStorage.setItem("login", true);
                        console.log("navigatingggg  from signup");
                        setIsDefaultSplash(false);
                        if (series && loginDetails?.isAlreadyPurchasedCourse == true) {
                            message.success("You've already purchased this test.It's assigned to you.");
                            setPageKey(6);
                            setIsDrawerNavigation(false);
                            setTimeout(() => {
                                navigate("/Home");
                            }, 2000);
                        } else if (series) {
                            //navigate("/Home", { state: { series } });
                            handleShowCheckout();
                        } else {
                            navigate("/Home");
                        }
                        setLoading(false);
                        activateMessages();
                    } else {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        console.log(
                            new Date(now) >= new Date(validFromLocal),
                            "xx",
                            new Date(now) <= new Date(validToLocal),
                            "case"
                        );
                        setLoading(false);
                        setAlertMessage("User account is not active.");
                        setSeverty("error");
                        setOpen(true);
                    }
                } else {
                    showProviderList(
                        loginDetails.userDetails.providerLists != null &&
                        loginDetails.userDetails.providerLists["$values"]
                    );
                }
            } else {
                setLoading(false);
                setOpen(true);
                setSeverty("error");
                setAlertMessage("Please check userId or password");
                console.log("check username and password ");
            }
        } else {
            setLoading(false);
            console.log("user name not exist.");
        }
    }, [loginDetails]);

    useEffect(() => {
        if (parentFetchError === "Not Found") {
            setOpen(true);
            setSeverty("error");
            setAlertMessage("Please check userId or password");
            console.log("user name not exist.");
        }
        console.log("parentdata", parentLoginDetails);
        if (isSuccessParentLogin) {
            if (
                parentLoginDetails?.userDetails &&
                parentLoginDetails?.userDetails.userName.toLowerCase() ==
                loginUsername.toLowerCase() &&
                (parentLoginDetails?.userDetails.passCode == loginPassword ||
                    googleAuth == true)
            ) {
                console.log(parentLoginDetails, "CVer123");
                setUserId(parentLoginDetails?.userDetails.userId);
                setUserName(parentLoginDetails?.userDetails.fullName);
                setEmailC(parentLoginDetails?.userDetails.userName);
                setIsParentLogin(true);
                // let privileges = loginDetails?.privilegeObject ? JSON.parse(loginDetails?.privilegeObject) : "";
                // setUserPrivileges(privileges?.Value);

                setPageKey(1);
                // Common Url - check diff 18/05/2024.
                if (parentLoginDetails.userDetails.providerLists["$values"].length === 1) {
                    const validFromLocal = convertToLocalTime(
                        parentLoginDetails?.userDetails.validFrom,
                        false
                    );
                    const validToLocal = convertToLocalTime(
                        parentLoginDetails?.userDetails.validTo,
                        false
                    );
                    const now = getCurrentDateTimeFormatted();

                    if (
                        new Date(now) >= new Date(validFromLocal) &&
                        new Date(now) <= new Date(validToLocal)
                    ) {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        setProviderId(parentLoginDetails.userDetails.providerId);
                        axios.defaults.headers.common = {
                            providerId: parentLoginDetails.userDetails.providerId,
                        };
                        setProviderName(
                            parentLoginDetails.userDetails.providerLists["$values"][0].providerName
                        );
                        //setPrivileges();
                        let privileges = parentLoginDetails?.privilegeObject
                            ? JSON.parse(parentLoginDetails?.privilegeObject)
                            : "";
                        console.log("Provider Privileges", privileges);
                        setUserPrivileges(privileges?.Value);
                        console.log("Provider PackageId", privileges?.Value.PackageId);
                        setPackageId(privileges?.Value.PackageId);
                        getDataParent();
                        sessionStorage.setItem("login", true);
                        console.log("navigatingggg  from signup");
                        navigate("/Home");
                        activateMessages();
                    } else {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        console.log(
                            new Date(now) >= new Date(validFromLocal),
                            "xx",
                            new Date(now) <= new Date(validToLocal),
                            "case"
                        );
                        setAlertMessage("User account is not active.");
                        setSeverty("error");
                        setOpen(true);
                    }
                } else {
                    // showProviderList(
                    //     loginDetails.userDetails.providerLists != null &&
                    //     loginDetails.userDetails.providerLists["$values"]
                    // );
                    const validFromLocal = convertToLocalTime(
                        parentLoginDetails?.userDetails.validFrom,
                        false
                    );
                    const validToLocal = convertToLocalTime(
                        parentLoginDetails?.userDetails.validTo,
                        false
                    );
                    const now = getCurrentDateTimeFormatted();

                    if (
                        new Date(now) >= new Date(validFromLocal) &&
                        new Date(now) <= new Date(validToLocal)
                    ) {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        setProviderId(parentLoginDetails.userDetails.providerId);
                        axios.defaults.headers.common = {
                            providerId: parentLoginDetails.userDetails.providerId,
                        };
                        let providerLists = parentLoginDetails.userDetails.providerLists["$values"];
                        let data = providerLists.find(item => item.id == parentLoginDetails.userDetails.providerId);
                        console.log("doublelogin", providerLists, data);
                        setProviderName(
                            data?.providerName
                        );
                        let privileges = parentLoginDetails?.privilegeObject
                            ? JSON.parse(parentLoginDetails?.privilegeObject)
                            : "";
                        console.log("Provider Privileges", privileges);
                        setUserPrivileges(privileges?.Value);
                        console.log("Provider PackageId", privileges?.Value.PackageId);
                        setPackageId(privileges?.Value.PackageId);
                        getDataParent();
                        sessionStorage.setItem("login", true);
                        console.log("navigatingggg  from signup");
                        navigate("/Home");
                        activateMessages();
                    } else {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        console.log(
                            new Date(now) >= new Date(validFromLocal),
                            "xx",
                            new Date(now) <= new Date(validToLocal),
                            "case"
                        );
                        setLoading(false);
                        setAlertMessage("User account is not active.");
                        setSeverty("error");
                        setOpen(true);
                    }
                }
            } else {
                setOpen(true);
                setSeverty("error");
                setAlertMessage("Please check userId or password");
                console.log("check username and password ");
            }
        } else {
            console.log("user name not exist.");
        }
    }, [parentLoginDetails]);


    const changeLang = (data) => {
        // alert(data);
        sessionStorage.setItem("lang", data);
        i18next.changeLanguage(data);
    };
    useEffect(() => {
        let currentLang = sessionStorage.getItem("lang");
        i18next.changeLanguage(currentLang);
    }, []);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    return (
        <Container maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: '2rem',
                    borderRadius: '10px',
                    maxWidth: "450px",
                    width: "100%",
                }}
            >
                <Typography variant="h4" align="center" gutterBottom>
                    Login
                </Typography>
                {isLoginAsParent ?
                    (<>
                        <Box component="form" onSubmit={onParentLoginSubmit} sx={{ mt: 3 }}>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="left" gutterBottom>
                                    Parent Email
                                </Typography>
                                <TextField fullWidth type="email" variant="outlined" required
                                    name="parentName"
                                    size="small"
                                    onChange={handleParentUserName}
                                    sx={{ mb: 1 }}
                                    InputProps={{ style: { fontSize: "1.5rem" } }}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="left" gutterBottom>
                                    Password
                                </Typography>
                                <TextField fullWidth
                                    type={showPassword ? "text" : "password"}
                                    variant="outlined"
                                    required
                                    name="password"
                                    size="small"
                                    sx={{ mb: 1 }}
                                    onChange={handleChangePassword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleTogglePasswordVisibility}
                                                    onMouseDown={(e) => e.preventDefault()} // Prevent focus loss on click
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: { fontSize: "1.5rem" }
                                    }}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="left" gutterBottom>
                                    Student Email
                                </Typography>
                                <TextField fullWidth type="email" variant="outlined" required
                                    name="userName"
                                    size="small"
                                    onChange={handleChangeEmail}
                                    sx={{ mb: 1 }}
                                    InputProps={{ style: { fontSize: "1.5rem" } }}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size='small'

                                    //onClick={onSubmit}
                                    sx={{
                                        width: '50%', // Button width reduced and centered
                                        paddingY: '10px',
                                        fontSize: '1rem',
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                    }}
                                    disabled={loading}
                                >
                                    {loading ? "logging..." : "Login"}
                                </Button>
                            </Box>
                        </Box>
                        <Box textAlign="center" sx={{ mt: 2 }}>
                            <Typography variant="h6">
                                Back to sign in{' '}
                                <Link onClick={() => setLoginAsParent(false)} style={{ cursor: 'pointer', color: theme.palette.primary.main }}>
                                    Log In
                                </Link>
                            </Typography>
                        </Box>
                    </>
                    )
                    :
                    (
                        <>
                            <Box component="form" onSubmit={onSubmit} sx={{ mt: 3 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="left" gutterBottom>
                                        Email
                                    </Typography>
                                    <TextField fullWidth type="email" variant="outlined" required
                                        name="userName"
                                        size="small"
                                        onChange={handleChangeEmail}
                                        sx={{ mb: 1 }}
                                        InputProps={{ style: { fontSize: "1.5rem" } }}
                                        InputLabelProps={{ shrink: false }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="left" gutterBottom>
                                        Password
                                    </Typography>
                                    <TextField fullWidth
                                        type={showPassword ? "text" : "password"}
                                        variant="outlined"
                                        required
                                        name="password"
                                        size="small"
                                        sx={{ mb: 1 }}
                                        onChange={handleChangePassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleTogglePasswordVisibility}
                                                        onMouseDown={(e) => e.preventDefault()} // Prevent focus loss on click
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            style: { fontSize: "1.5rem" }
                                        }}
                                        InputLabelProps={{ shrink: false }}
                                    />
                                </Grid>
                                <Box textAlign="center" sx={{ mt: 2 }}>
                                    <Typography variant="h6">
                                        <Link onClick={() => setLoginAsParent(true)} style={{ cursor: 'pointer', color: theme.palette.primary.main }}>
                                            Login as Parent
                                        </Link>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size='small'

                                        //onClick={onSubmit}
                                        sx={{
                                            width: '50%', // Button width reduced and centered
                                            paddingY: '10px',
                                            fontSize: '1rem',
                                            backgroundColor: (theme) => theme.palette.primary.main,
                                        }}
                                        disabled={loading}
                                    >
                                        {loading ? "logging..." : "Login"}
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                {isLoginAsParent == false &&
                    <Box textAlign="center" sx={{ mt: 2 }}>
                        <Typography variant="h6">
                            Don't have an account?{' '}
                            <Link onClick={() => navigate('/medineer/SignUp', { state: { series } })} style={{ cursor: 'pointer', color: theme.palette.primary.main }}>
                                Sign Up
                            </Link>
                        </Typography>
                    </Box>
                }
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={open}
                onClose={handleClose}
                autoHideDuration={3000}
            >
                <Alert
                    severity={severty}
                    onClose={handleClose}
                    sx={{ fontSize: "15px", width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            {showModel}
            {
                showCheckout && <MedineerLogin course={series} existingUserEmail={loginDetails?.userDetails?.userId != 0 ? null : loginDetails?.userDetails?.userName} closeModal={handleHideCheckout} />
            }
        </Container>
    );
}

export default NewSignIn;
